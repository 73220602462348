export const LOCAL = 'local';
export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';

export const LOCAL_URL = 'http://localhost:3001';
export const DEVELOPMENT_URL = 'https://puce-auth.nzxt.com';
export const STAGING_URL = 'https://chartreuse-auth.nzxt.com';
export const PRODUCTION_URL = 'https://auth.nzxt.com';

export const ENVIRONMENT_URL_MAP = {
  [LOCAL]: LOCAL_URL,
  [DEVELOPMENT]: DEVELOPMENT_URL,
  [STAGING]: STAGING_URL,
  [PRODUCTION]: PRODUCTION_URL,
};
