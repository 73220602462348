/* eslint-disable max-len */
export default {
  sm: '(min-width: 0px) and (max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 1023px)',
  lg: '(min-width: 1024px) and (max-width: 1279px)',
  xl: '(min-width: 1280px) and (max-width: 1536px)',
  '2xl': '(min-width: 1537px) and (max-width: 2000px)',
  '3xl': '(min-width: 2000px)',

  tallScreen: '(min-height: 950px)',
  shortScreen: '(max-height: 650px)',
  xlTallScreen: '(min-height: 2000px)',
  ipadPro:
    '(min-width: 1024px) and (max-height: 1366px) and (orientation: portrait)and (-webkit-min-device-pixel-ratio: 1.5)',
  smLandscape:
    '(min-width: 0px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)',
  mdLandscape:
    '(min-width: 813px) and (max-width : 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)',
};
