/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { signOut } from 'next-auth/react';
import type { HookInput, HookFetcher, HookFetcherOptions } from './types';
import { CommerceError, ERROR_CODES } from './errors';
import { useCommerce } from '..';

export type SwrOptions<Result, Input = null> = SWRConfiguration<
  Result,
  CommerceError,
  HookFetcher<Result, Input>
>;

export type UseData = <Result = any, Input = null>(
  options: HookFetcherOptions | (() => HookFetcherOptions | null),
  input: HookInput,
  fetcherFn: HookFetcher<Result, Input>,
  swrOptions?: SwrOptions<Result, Input>
) => SWRResponse<Result, CommerceError>;

const useData: UseData = (options, input, fetcherFn, swrOptions) => {
  const { fetcherRef } = useCommerce();
  const fetcher = async (
    url?: string,
    query?: string,
    method?: string,
    ...args: any[]
  ) => {
    try {
      return await fetcherFn(
        { url, query, method },
        // Transform the input array into an object
        args.reduce((obj, val, i) => {
          obj[input[i][0]!] = val;
          return obj;
        }, {}),
        fetcherRef.current
      );
    } catch (error) {
      // SWR will not log errors, but any error that's not an instance
      // of CommerceError is not welcomed by this hook
      if (!(error instanceof CommerceError)) {
        console.error(error);
      }
      throw error;
    }
  };
  const response = useSWR(
    () => {
      const opts = typeof options === 'function' ? options() : options;
      const cacheKeys = input.map(e => e[1]);
      // if any cache key is undefined do not fetch
      return opts && !cacheKeys.includes(undefined)
        ? [opts.url, opts.query, opts.method, ...cacheKeys]
        : null;
    },
    fetcher,
    swrOptions
  );

  if (
    response.error &&
    response.error.code === ERROR_CODES.InvalidAccessToken
  ) {
    signOut({ callbackUrl: process.env.NEXT_PUBLIC_BASE_URL });
  }

  if (!('isLoading' in response)) {
    Object.defineProperty(response, 'isLoading', {
      get() {
        return typeof response.data === 'undefined';
      },
      enumerable: true,
    });
  }

  return response as typeof response & { isLoading: boolean };
};

export default useData;
