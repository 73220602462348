import { parseCookies } from 'nookies';
import { HEAP_ID_COOKIE_NAME } from '@constants';

type GetId = () => string | undefined;

const getId: GetId = () => {
  const cookies = parseCookies();

  return cookies[HEAP_ID_COOKIE_NAME];
};

export default getId;
