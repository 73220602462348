function gtag(...rest): void {
  window.dataLayer.push(...rest);
}

const onRevoke = (): void => {
  if (typeof window !== 'undefined') {
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA4_ID}`] = true;
    window[`ga-disable-${process.env.NEXT_PUBLIC_UA_ID}`] = true;

    gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
  }
};

export default onRevoke;
