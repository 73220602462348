import getLang from './get-lang';
import getRegion from './get-region';
import getToken from './get-token';

type MaxifyHeaders = {
  locale: string;
  region: string;
  token: string;
};

export default function getMaxifyHeaders(locale: string): MaxifyHeaders {
  return {
    locale: getLang(locale),
    region: getRegion(locale),
    token: getToken() || ' ',
  };
}
