export const MAX_FPS = 130;
export const DEFAULT_VARIANT_REGION = 'variantsNorthernamerica';
export const FPS_BUTTON_LABEL = [
  { label: '1080', value: '1080' },
  { label: '1440', value: '1440' },
];
export const PRICE_OPTIONS_LABEL = [
  { label: '1000', value: '1000' },
  { label: '1500', value: '1500' },
  { label: '2000', value: '2000' },
  { label: '2500', value: '2500' },
];
