// Modal Views
export const MODAL_VIEW_SIGNUP = 'SIGNUP_VIEW';
export const MODAL_VIEW_LOGIN = 'LOGIN_VIEW';
export const MODAL_VIEW_FORGOT = 'FORGOT_VIEW';
export const MODAL_VIEW_RESET = 'RESET_VIEW';
export const MODAL_VIEW_SР = 'RESET_VIEW';
export const MODAL_VIEW_BLD_OPTIONS = 'BLD_OPTIONS';
export const MODAL_VIEW_BLD_MORE_INFO = 'BLD_MORE_INFO';
export const MODAL_VIEW_BLD_SUMMARY = 'BLD_SUMMARY';
export const MODAL_VIEW_EXPLORE = 'EXPLORE';
export const MODAL_VIEW_BLD_EXTRAS = 'BLD_EXTRAS';
export const MODAL_VIEW_BLD_ISSUES = 'BLD_ISSUES';
export const MODAL_LOAD_BLD_PC = 'LOAD_BLD_PC';
export const MODAL_LOAD_BLD_KB = 'LOAD_BLD_KB';
export const MODAL_SHARE_BLD_PC = 'SHARE_BLD_PC';
export const MODAL_SHARE_BLD_KB = 'SHARE_BLD_KB';
export const MODAL_NOTIFICATION_SIGNUP_VIEW = 'NOTIFICATION_SIGN_UP_VIEW';
export const MODAL_VIEW_BLD_INFO_HELP = 'BLD_INFO_HELP';
export const MODAL_VIEW_PRODUCT_DETAILS = 'PRODUCT_DETAILS';
export const MODAL_VIEW_EMAIL_SIGN_UP = 'MODAL_VIEW_EMAIL_SIGN_UP';
export const MODAL_VIEW_PRODUCT_POLICY = 'MODAL_VIEW_PRODUCT_POLICY';
export const AFFIRM_LARGE_SIZE = 'AFFIRM_LARGE_SIZE';
export const AFFIRM_SMALL_SIZE = 'AFFIRM_SMALL_SIZE';
export const MODAL_NOTICE_PAGE = 'NOTICE_PAGE';

// Modal aria labels
export const MODAL_ARIA_LABEL_SIGNUP = 'Sign up Form';
export const MODAL_ARIA_LABEL_LOGIN = 'Login Form';
export const MODAL_ARIA_LABEL_FORGOT = 'Forgot password form';
export const MODAL_ARIA_LABEL_RESET = 'Reset password form';
export const MODAL_ARIA_LABEL_BLD_OPTIONS = 'BLD Options';
export const MODAL_ARIA_LABEL_BLD_MORE_INFO = 'BLD More Info';
export const MODAL_ARIA_LABEL_BLD_SUMMARY = 'BLD Summary';
export const MODAL_ARIA_LABEL_EXPLORE = 'Explore BLD Category';
export const MODAL_ARIA_LABEL_EXTRAS = 'BLD Extras';
export const MODAL_ARIA_LABEL_MODAL_VIEW_BLD_ISSUES = 'BLD Issue';
export const MODAL_ARIA_LABEL_MODAL_LOAD_BLD_PC = 'Load BLD PC';
export const MODAL_ARIA_LABEL_MODAL_LOAD_BLD_KB = 'Load BLD KB';
export const MODAL_ARIA_LABEL_MODAL_SHARE_BLD_PC = 'Share BLD PC';
export const MODAL_ARIA_LABEL_MODAL_SHARE_BLD_KB = 'Share BLD KB';
export const MODAL_ARIA_NOTIFICATION_SIGNUP_VIEW = 'Sign Up for Notifications';
export const MODAL_ARIA_BLD_INFO_HELP = 'Category details';
export const MODAL_ARIA_EMAIL_SIGN_UP = 'Sign Up for Emails';
export const MODAL_ARIA_PRODUCT_POLICY = 'Product Policy Information';
export const MODAL_ARIA_PRODUCT_DETAILS = 'Product Details';
export const MODAL_ARIA_UPSELL_VIEW = 'Additional Products for Purchase';

export const MODAL_ARIA_LABEL_MAP = {
  [MODAL_VIEW_SIGNUP]: MODAL_ARIA_LABEL_SIGNUP,
  [MODAL_VIEW_LOGIN]: MODAL_ARIA_LABEL_LOGIN,
  [MODAL_VIEW_FORGOT]: MODAL_ARIA_LABEL_FORGOT,
  [MODAL_VIEW_RESET]: MODAL_ARIA_LABEL_RESET,
  [MODAL_VIEW_BLD_OPTIONS]: MODAL_ARIA_LABEL_BLD_OPTIONS,
  [MODAL_VIEW_BLD_MORE_INFO]: MODAL_ARIA_LABEL_BLD_MORE_INFO,
  [MODAL_VIEW_EXPLORE]: MODAL_ARIA_LABEL_EXPLORE,
  [MODAL_VIEW_BLD_SUMMARY]: MODAL_ARIA_LABEL_BLD_SUMMARY,
  [MODAL_VIEW_BLD_EXTRAS]: MODAL_ARIA_LABEL_EXTRAS,
  [MODAL_VIEW_BLD_ISSUES]: MODAL_ARIA_LABEL_MODAL_VIEW_BLD_ISSUES,
  [MODAL_LOAD_BLD_PC]: MODAL_ARIA_LABEL_MODAL_LOAD_BLD_PC,
  [MODAL_LOAD_BLD_KB]: MODAL_ARIA_LABEL_MODAL_LOAD_BLD_KB,
  [MODAL_SHARE_BLD_PC]: MODAL_ARIA_LABEL_MODAL_SHARE_BLD_PC,
  [MODAL_SHARE_BLD_KB]: MODAL_ARIA_LABEL_MODAL_SHARE_BLD_KB,
  [MODAL_NOTIFICATION_SIGNUP_VIEW]: MODAL_ARIA_NOTIFICATION_SIGNUP_VIEW,
  [MODAL_VIEW_BLD_INFO_HELP]: MODAL_ARIA_BLD_INFO_HELP,
  [MODAL_VIEW_EMAIL_SIGN_UP]: MODAL_ARIA_EMAIL_SIGN_UP,
  [MODAL_VIEW_PRODUCT_POLICY]: MODAL_ARIA_PRODUCT_POLICY,
  [MODAL_VIEW_PRODUCT_DETAILS]: MODAL_ARIA_PRODUCT_DETAILS,
};

// Color Values
export const NZXT_WHITE = 'FFFFFF';
export const NZXT_LIGHT_GRAY_50 = '#F5F5F8';

// NAV HEIGHTS
export const SM_NAV_HEIGHT_NUM = 65;
export const SM_NAV_HEIGHT = '65px';
