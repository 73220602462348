/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'Kundenkonto',
  user_settings_change_password: 'Passwort ändern',
  user_settings_save: 'Speichern',
  user_settings_cancel: 'Abbrechen',
  user_profile_heading: 'Profil',
  user_saved_builds_heading: 'Gespeicherte Builds',
  user_saved_builds_bld_name: 'Build-Name',
  user_saved_builds_bld_date: 'Erstellungsdatum',
  user_saved_builds_bld_item_part: 'Artikel',
  user_saved_builds_delete_bld: 'BLD löschen',
  user_saved_builds_resume_bld: 'BLD fortsetzen',
  user_saved_builds_hero_title:
    'Entwickelt, um deinen Build in Szene zu setzen',
  user_saved_builds_hero_text:
    'Das kompakte vertikale Design unterstützt die meisten Grafikkarten in voller Größe auf dem Markt, sodass du deine Schreibtischfläche voll ausnutzen kannst, ohne Einbußen bei der Grafikleistung.',
  user_saved_builds_pc_heading: 'PC-Aufbauten',
  user_saved_builds_keyboard_heading: 'Tastatur baut auf',
  user_saved_builds_view_pc: 'PC-Aufbauten ansehen',
  user_saved_builds_view_keyboard: 'Tastatur-Builds anzeigen',
  user_saved_builds_no_pc: 'Sie haben keine gespeicherten PC Builds',
  user_saved_builds_no_kb: 'Sie haben keine gespeicherten Keyboard Builds',
  user_order_history_heading: 'Bestellverlauf',
  user_order_history_date: 'Datum',
  user_order_history_order_number: 'Bestellnummer',
  user_order_history_status: 'Status',
  user_order_history_amount: 'Betrag',
  user_order_history_view: 'Beleg anzeigen',
  user_order_history_empty_view: 'Du hast keinen Bestellverlauf.',
  user_order_history_shipping: 'Versandadresse',
  user_order_history_billing: 'Rechnungsadresse',
  user_order_history_item: 'Artikel',
  user_order_history_item_image: 'Produktfoto',
  user_order_history_quantity: 'Anzahl',
  user_order_history_order: 'Auftrag #',
  user_support_heading: 'Support + Rücksendungen',
  user_data_heading: 'Datenanforderung',
  user_terms_heading: 'Bedingungen',
  user_terms_empty: 'Sie haben noch keine unterschriebenen Bedingungen.',
  user_support_subject_min_length:
    'Betreff muss mindestens 3 Zeichen lang sein',
  user_support_message_placeholder:
    'Bitte Gründe für den Kontakt angeben. Bei Rücksendung bitte Bestellnummer angeben',
  user_support_update_button: 'Aktualisieren',
  user_active_tickets_heading: 'Aktive Tickets',
  user_active_tickets_chat: 'Nachricht hinzufügen',
  user_active_tickets_no_chat_history: 'Du hast keinen Chat-Verlauf',
  view_chat_button: 'Chat anzeigen',
  close_chat_button: 'Chat schließen',
  field_required_message: 'Dieses Feld ist erforderlich',
  label_accept: 'Akzeptieren',
  label_reason_for_contact: 'Grund der Kontaktaufnahme',
  label_country: 'Land',
  label_subject: 'Betreff',
  label_product: 'Produkt',
  label_message: 'Nachricht',
  label_requestor_type: 'Anfragetyp',
  label_country_of_residence: 'Land des Wohnsitzes',
  label_description: 'Beschreibung',
  placeholder_description: 'Beschreibung deiner Anfrage',
  label_submit: 'Abschicken',
  message_no_support_requests: 'Du hast keine aktiven Supportanfragen',
  label_ticket_number: 'Ticketnummer',
  label_date_created: 'Datum erstellt',
  label_status: 'Status',
  label_department: 'Abteilung',
  subheading_active_ticket:
    'Wenn du Hilfe benötigst oder einen Artikel zurücksenden möchtest, wende dich bitte an den Support.',
  label_create_support_request: 'Supportanfrage erstellen',
  button_comment: 'Kommentar',
  label_for_myself: 'Für mich selbst',
  label_parent: 'Elternteil',
  label_legal_rep: 'Gesetzlicher Vertreter',
  label_relative: 'Aneghöriger',
  label_guardian: 'Erziehungsberechtigter',
  label_accept_generic_terms:
    'Ich habe diese Bedingungen gelesen und akzeptiere sie',
  error_view_message: 'Ups, etwas ist schief gelaufen.',
  signout_view_message: 'Du wurdest abgemeldet.',
  user_order_history_close: 'Schließen',
  birthday_error_message: 'Bitte gib ein gültiges Geburtsdatum ein',
  label_view_saved: 'Alle gespeicherten Builds anzeigen',
};

const formErrors = {
  required_error: 'Erforderlich',
  invalid_email: 'Bitte gib eine gültige E-Mail ein',
  username_full_pattern_message:
    'Der Benutzername muss 6 bis 30 Zeichen lang sein. Sie können Buchstaben, Zahlen und Punkte verwenden, dürfen jedoch keine aufeinanderfolgenden Punkte beginnen, enden oder enthalten.',
  password_pattern_message:
    'Das Passwort muss mindestens sechs Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten',
  username_min_length_message:
    'Verwende 6 Zeichen oder mehr für deinen Benutzernamen',
  passwords_must_match: 'Passwörter müssen übereinstimmen',
  invalid_current_password:
    'Das Passwort kann nicht aktualisiert werden. Der als aktuelles Passwort angegebene Wert ist falsch',
  must_accept_partner_program_terms:
    'Sie müssen die Bedingungen des Partnerprogramms akzeptieren, um ein NZXT-Partner zu werden',
  must_accept_tos_terms:
    'Du musst den Nutzungsbedingungen zustimmen, um einen NZXT Account anzulegen',
  must_accept_generic_terms: 'Sie müssen diesen Bedingungen zustimmen',
};

const auth = {
  user_auth_agreement: "Ich akzeptiere NZXT's.",
  user_auth_privacy_policy: 'Datenschutzrichtlinie',
  user_auth_and: 'und habe die',
  user_auth_terms: 'Nutzungsbedingungen gelesen.',
  error_invalid_request: 'Ungültige Anfrage.',
  error_invalid_token: 'Ungültiges Benutzerauthentifizierungstoken',
  error_session_expired:
    'Deine Sitzung ist abgelaufen. Bitte melde dich erneut an, um fortzufahren.',
  user_forgot_required_email: 'E-Mailadresse wird benötigt',
  user_forgot_success_message:
    'Vielen Dank! Wenn mit dieser E-Mail ein Konto verknüpft ist, senden wir die Anweisungen zum Zurücksetzen des Kennworts sofort.',
  error_email_exists: 'Die E-Mail wird bereits verwendet',
  user_email_verification_heading: 'E-Mail-Verifizierung',
  error_email_not_verified:
    'Die E-Mail-Adresse ist noch nicht bestätigt. Um die Bestätigungs-E-Mail erneut zu senden, klicken Sie auf',
  error_email_already_verified:
    'Die E-Mail-Adresse wurde bereits verifiziert. Bitte melden Sie sich weiter an.',
  user_email_verification_success_message:
    'Ihre E-Mail-Adresse wurde erfolgreich verifiziert!. Bitte melden Sie sich weiter an.',
  error_username_unavailable: 'Der Benutzername ist nicht verfügbar',
  error_reset_password_same_as_old_password:
    'Dieses Passwort ist das gleiche wie zuvor. Bitte wähle ein anderes.',
  error_reset_password_link_expired:
    'Der Link zum Zurücksetzen deines Passworts ist abgelaufen.',
  error_reset_password_link_invalid:
    'Der Link zum Zurücksetzen deines Passworts ist ungültig.',
  user_forgot_password_subheading:
    'Gib deine E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen deines Passworts zu erhalten.',
  user_reset_password_heading: 'Setze dein Passwort zurück',
  user_reset_password_subheading: 'Bitte gib ein neues Passwort ein.',
  user_reset_password: 'Passwort zurücksetzen',
  user_reset_success_message:
    'Du hast dein Passwort erfolgreich zurückgesetzt!',
  user_login_text: 'Mit Google anmelden',
  user_login_remember: 'Angemeldet bleiben',
  user_login_forgot_password: 'Passwort vergessen?',
  user_login_wrong_email_or_password: 'Falsche E-Mail-Adresse oder Passwort',
  user_login_success_message: 'Du hast dich erfolgreich angemeldet!',
  error_too_many_requests:
    'Du hast zu viele Anfragen gestellt. Bitte warte einige Minuten oder wende dich an den Support',
  error_user_not_found: 'Benutzer wurde nicht gefunden',
  user_sign_in_sign_up: 'Anmelden / Registrieren',
  user_sign_out: 'Abmelden',
  user_sign_in: 'Anmelden',
  user_account_updates: 'NZXT-Updates',
  user_account_no_updates:
    'Keine aktuellen Updates, schauen Sie später noch einmal vorbei.',
  support_center_link: 'Hilfe & Support',
  error_google_account_exists:
    'Du hast schon einen Account, der mit diesem Google Konto verknüpft ist. Bitte logge dich stattdessen ein.',
  error_local_account_exists:
    'Sieht so aus, als hättest du bereits einen Account. Bitte logge dich stattdessen ein.',
  user_sign_up: 'Registrieren',
  user_sign_up_text: 'Mit Google registrieren',
  user_sign_up_opt_in:
    'Melde dich für E-Mails an und erhalte Updates von NZXT über Produkte, BLD und CAM',
  user_sign_up_check_email_message:
    'Sieh in deinem Postfach nach und klicke auf den Link zum Aktivieren deines Kontos.',
  user_sign_up_success_verify_message:
    'Das Konto wird erst nach der Verifizierung aktiviert. Bitte sieh in deinem Spam-Ordner nach, wenn du die E-Mail nach einigen Minuten noch nicht erhalten hast.',
  user_info_updated_success_message:
    'Deine Informationen wurden erfolgreich aktualisiert!',
  user_info_newsletter_confirmation_message:
    'Bitte überprüfe dein E-Mail-Postfach zur Bestätigung.',
  user_username_min_length_text:
    'Benutzername muss aus mindestens 5 Zeichen bestehen',
  user_email_sign_in: 'Oder fortfahren mit',
  user_no_account: 'Noch kein Kundenkonto?',
  user_create_account: 'Kundenkonto anlegen',
  user_email_sign_up_text: 'Oder registrieren mit',
  user_have_account: 'Hast du ein Kundenkonto?',
  open_login_view: 'Login-Ansicht öffnen',
  error_newsletter_subscription:
    'Newsletter kann derzeit nicht abonniert / abbestellt werden. Bitte versuche es später noch einmal.',
  user_login_too_many_requests:
    'Du hast zu viele Anfragen gestellt. Bitte warte ein paar Minuten oder kontaktiere den Support',
  label_email_address: 'E-Mail Addresse',
  label_password: 'Passwort',
  label_current_password: 'Jetziges Passwort',
  label_confirm_password: 'Passwort wiederholen',
  label_new_password: 'Neues Kennwort',
  label_confirm_new_password: 'Bestätige neues Passwort',
  label_username: 'Nutzername',
  label_first_name: 'Vorname',
  label_last_name: 'Nachname',
  label_preferred_pronouns: 'Bevorzugte Pronomen',
  label_search_for_help_articles: 'Suche nach Hilfeartikeln',
  label_birthday: 'Geburtstag',
  label_location: 'Ort',
  app_access: 'möchte auf dein NZXT Konto zugreifen, um Folgendes abzurufen:',
  app_access_has_scopes: 'möchte auf dein NZXT Konto zugreifen',
  scope_profile: 'Dein Profil mit deinem Namen und Bild',
  scope_email: 'Deine E-Mail-Adresse',
  scope_address: 'Ihre physische Adresse',
  scope_phone: 'Deine Telefonnummer',
  grant_offline_access: 'Offline-Zugriff haben',
  grant_offline_access_previous: 'die Sie zuvor gewährt haben',
};

const build = {
  custom_bld_modal_heading: 'Baue deinen PC',
  custom_bld_modal_subheading: 'Wähle deinen Chipsatz und dein Budget',
  custom_bld_unavailable: 'Bitte wähle einen anderen Artikel aus',
  custom_bld_issue: 'Die folgenden Probleme müssen gelöst werden: ',
  custom_bld_title: 'Dein BLD',
  custom_bld_choose_chipset: 'Wähle dein Chipsatz',
  custom_bld_lets_get_started: 'Los geht‘s',
  custom_bld_destrutive_action: 'Warnung: Build zurückgesetzt',
  custom_bld_confirm_reset:
    'Dein Build wird mit dem folgenden Chipsatz zurückgesetzt:',
  custom_bld_keyboard_confirm_reset: `Ihr Build wird mit dieser Änderung zurückgesetzt`,
  custom_bld_chipset: 'Chipsatz',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Mehr Erfahren',
  custom_bld_select: 'Auswählen',
  review_bld_button: 'BLD Übersicht',
  custom_bld_summary: 'Zusammenfassung',
  custom_bld_budget: 'Budget',
  custom_bld_specs: 'Technische Daten',
  custom_bld_case: 'Gehäuse ',
  custom_bld_cases: 'Gehäuse',
  custom_bld_caseCooling: 'Kühlung',
  custom_bld_fans: 'Lüfter',
  custom_bld_cpu: 'CPU',
  custom_bld_gpu: 'Grafikkarte',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'HDD',
  custom_bld_specials: 'Extras',
  custom_bld_colors: 'Farben',
  custom_bld_color: 'Farbe',
  custom_bld_black: 'Schwarz',
  custom_bld_red: 'Rot',
  custom_bld_white: 'Weiß',
  custom_bld_grey: 'Grau',
  custom_bld_blue: 'Blau',
  custom_bld_purple: 'Lila',
  custom_bld_yellow: 'Gelb',
  custom_bld_audio: 'Audio',
  custom_bld_extras: 'Extras',
  custom_bld_motherboard: 'Motherboard',
  custom_bld_motherboards: 'Motherboards',
  custom_bld_powerSupplies: 'Netzteile',
  custom_bld_powerSupply: 'Netzteil',
  custom_bld_services: 'Services',
  custom_bld_software: 'Software',
  custom_bld_monitors: 'Monitore',
  custom_bld_peripherals: 'Peripheriegeräte',
  custom_bld_lighting: 'Beleuchtung',
  custom_bld_headset: 'Headset',
  custom_bld_keyboard: 'Tastatur',
  custom_bld_all: 'Alle',
  custom_bld_mouse: 'Maus',
  custom_bld_storage: 'Speicher',
  custom_bld_streaming_ready: 'Stream Ready',
  custom_bld_rgb_lighting: 'RGB-Beleuchtung',
  custom_bld_vr_ready: 'VR Ready',
  custom_bld_wifi: 'WLAN',
  custom_bld_share_build_message:
    'Dein Build wurde erfolgreich gespeichert und kopiert!',
  custom_bld_from: 'Von',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    'Dein Build wurde erfolgreich gespeichert! Du kannst ihn unter "Gespeicherte Builds" in deinem Konto ansehen.',
  custom_bld_save_modal_message:
    'Möchten Sie diesen Build für später speichern?',
  custom_bld_confirm_save_modal_message:
    'Wenn Sie diese Seite verlassen, gehen nicht gespeicherte Änderungen verloren.',
  help_me_decide: 'Entscheidungshilfe',
  save_build: 'Build speichern',
  start_over: 'Neu starten',
  summary: 'Zusammenfassung',
  build_summary: 'Build Zusammenfassung',
  load_build: 'Build laden',
  load: 'Laden',
  save: 'Speichern',
  restart: 'Neu starten',
  share: 'Teilen',
  my_build_label: 'Mein Build',
  est_shipping: 'Voraussichtliches Versanddatum',
  add_to_build: 'Zum Build hinzufügen',
  message_invalid: 'Momentan nicht verfügbar',
  message_out_of_stock: 'Nicht auf Lager',
  message_low_stock: 'Niedriger Bestand',
  message_best_seller: 'Bestseller',
  message_coming_soon: 'Bald verfügbar',
  message_is_locked: 'Nicht Kompatibel',
  message_new_item: 'Neu',
  button_label_select: 'Auswählen',
  button_label_selected: 'Ausgewählt',
  button_label_add: 'Hinzufügen',
  button_label_remove: 'Entfernen',
  button_label_added: 'Hinzugefügt',
  button_label_update: 'Aktualisieren',
  button_label_info: 'Infos',
  button_label_more_info: 'Mehr Info',
  button_label_options_info: 'Optionen und Info',
  button_label_continue_to: 'Mit dem Build fortfahren',
  button_label_edit: 'Bearbeiten',
  build_fps_label:
    'Die FPS-Geschwindigkeiten werden mit einer Auflösung von 2560 x 1440 gemessen, wobei die höchste Grafikeinstellung jedes Spiels verwendet wird',
  build_includes: 'Beinhaltet',
  custom_bld_boost: 'Erweitere deinen Build',
  custom_bld_fps_unavailable: 'steht aus',
  custom_bld_amd_help:
    'Intel-basierte PCs waren in den letzten Jahren der Standard, aber AMD-PCs haben aufgeholt und eignen sich jetzt bestens für Gaming. Wir helfen dir, beide zu bauen, indem wir Motherboards und Kühler ausgewählt haben, die gut zusammen passen, sodass du dir keine Gedanken darum machen musst. Wir empfehlen dir, die Balance zwischen der hier angezeigten FPS Performance und deinem Budget zu finden.',
  custom_bld_to_see_this: 'Um diese Auswahl zu sehen musst du',
  custom_bld_change_chipset: 'deinen Chipsatz ändern.',
  custom_bld_prebuild_upsell: 'Oder wähle einfach und schnell einen Prebuild',
  no_item_selected: 'Kein Produkt ausgewählt',
  build_change_label: 'Build angepasst',
  invalid_action_label: 'Verhindert',
  success_label: 'Erfolg',
  build_info_message: 'Info',
  reset_label: 'Build zurückgesetzt',
  complete_build_label: 'Vervollständige dein Build',
  back_to_build_label: 'Zurück zu deinem Build',
  complete_build_sub_label: 'Extras & Peripherie',
  back_to_build_sub_label: 'Core components of your build',
  performance: 'Leistung',
  message_error: 'Oh nein, offenbar ist etwas schiefgegangen',
  capacity_label: 'Kapazität',
  option_label: 'Option',
  options_label: 'Optionen',
  max_speed: 'Höchstgeschwindigkeit',
  modular_label: 'Modular',
  midtower_label: 'Mid-Tower',
  streaming_label: 'Streaming',
  save_and_exit: 'Speichern und Beenden',
  exit: 'Ausgang',
  view_cart_label: 'Warenkorb ansehen',
  cart_subtotal_label: 'Warenkorb Zwischensumme',
  start_build_label: 'Build starten',
  new_build_label: 'Neuer Build',
  continue_saved_build_label: 'Setzen Sie Ihr gespeichertes Build fort',
  continue_progress_build_label: 'Setzen Sie den laufenden Bau fort',
  continue_cart_build_label: 'Überprüfen Sie das Build in Ihrem Warenkorb',
  core_label: 'Basis',
  extras_label: 'Add-ons',
  summary_label: 'Zusammenfassung',
  share_build_pc: 'Benutzerdefinierter PC',
  share_build_kb: 'Benutzerdefinierte Tastatur',
  build_summary_label: 'Übersicht über deinen Tastatur-Build.',
  pc_hub_card_heading: 'Individuelle Gaming-PCs',
  pc_hub_card_subheading:
    'Baue deinen Traum-PC für dein Budget und optimiert für die Games, die du liebst.',
  kb_hub_card_heading: 'Individuelle Tastaturen',
  kb_hub_card_subheading:
    'Erstelle eine individuelle Tastatur und Maus, die zu deinem Setup und Spielstil passen.',
};

const keyboard = {
  keyboard_label: 'Tastatur',
  keyboard_size: 'Tastaturgröße',
  keyboard_base: 'Tastatursockel',
  keyboard_primay_base_color: 'Grundfarbe',
  keyboard_layout: 'Tastaturlayout',
  keyboard_summary: 'Zusammenfassung Tastatur',
  accent_keycaps: 'Akzent-Tastenkappen',
  cabletype: 'Accent Cable',
  additional_cable_color: 'Kabel Farbe',
  cable: 'Kabel',
  additional_cable: 'Zusätzliches Kabel',
  primary_keycaps: 'Base Keycaps',
  switches: 'Switches',
  size: 'Größe',
  layout: 'Layout',
  language: 'Sprache',
  mouse_pad_label: 'Mauspad',
  desk_mat_label: 'Schreibtischunterlage',
  tools_label: 'Werkzeuge',
  wrist_rest_label: 'Handballenauflage',
  mouse_label: 'Maus',
  cable_description:
    'Optionales farbiges Kabel. Ein standardmäßiges schwarzes 2-Meter-Kabel wird mitgeliefert.',
  cable_color_description: 'Die Farbe des Premium-Kabels.',
  language_description: 'Bestimmt die Position der Tasten Q, W, Z und A.',
  layout_description: 'Bestimmt die Größe und Position der Tasten.',
  keycap_accent_description: `Optionale Farbe für Escape-, Enter-, Pfeil- und Numpad-Enter-Tasten.`,
  primary_keycap_description: 'PBT farbstoffsublimiert, Cherry Profil',
  switch_description: 'Definiert das Tipperlebnis bei jedem Tastendruck.',
  keyboard_base_color_description: 'Die Hauptfarbe des Tastatursockels.',
  keyboard_base_size_description:
    'Bestimmt die Anzahl der Tasten und die Größe der Tastatur.',
  mouse_description: 'Leichte beidhändige Maus.',
  summary_description: 'Übersicht über deinen Tastatur-Build',
  premium_label: 'Premium',
  base_label: 'Basis',
  keyboard_warning:
    'Bitte wähle die folgenden Punkte aus, um mit deinem Build fortzufahren:',
  switch_reset_warning:
    'Ihre Schalter wurden zurückgesetzt. Bitte wählen Sie neue Schalter aus.',
  keycap_reset_warning:
    'Ihre Tastenkappen wurden zurückgesetzt. Bitte wählen Sie neue Tastenkappen.',
  base_type: 'Art des Builds',
  build_type_description: `Wählen Sie die Art der Tastatur, die Sie konfigurieren möchten`,
  full_custom_label: 'vollständige Anpassung',
  barebones_disclaimer:
    'Ändern Sie Ihre Art des Builds, um dieses Element auszuwählen',
  none_label: 'Keine',
};

const camCta = {
  cam_description:
    'Leistung, Temperaturen und Geräte mit einer einzigen Anwendung steuern.',
  cam_windows_10: 'WINDOWS 10 64BIT',
};

const helpCta = {
  help_description:
    'Hol dir umfangreiche Anleitungen und Support für alle deine NZXT Produkte sowie Unterstützung bei Garantiefällen, Rücksendung und technischen Fragen',
};

const emailSignUp = {
  email_stay_in_touch: 'Bleib auf dem Laufenden',
  email_sub_copy:
    'Erfahre zuerst von den neuesten Produkten, Angeboten und Neuigkeiten von NZXT',
  email_legal:
    'Mit deiner Anmeldung stimmst du den Nutzungsbedingungen für NZXT, CAM und BLD zu. ',
  email_notify: 'Benachrichtigt mich',
  email_notify_stock:
    'Lass dich benachrichtigen, wenn dieser Artikel verfügbar ist.',
};

const generic = {
  explore: 'Mehr Erfahren',
  cam_title: 'NZXT CAM',
  free_download: 'Kostenloser Download',
  details: 'Details',
  help_amp_support: 'Hilfe & Support',
  help_and_support: 'Hilfe und Support',
  read_more: 'Weiterlesen',
  privacy_policy: 'Datenschutzrichtlinie',
  terms_conditions: 'Geschäftsbedingungen',
  and: 'und',
  or: 'oder',
  send: 'senden',
  return_to: 'zurückkehren zu',
  previous: 'Zurück',
  next: 'Weiter',
  home: 'Home',
  keep_browsing: 'Weiter stöbern',
  create_build: 'Erstelle deinen Build',
  learn_more: 'Erfahre mehr',
  add_to_cart: 'Zum Warenkorb hinzufügen',
  share_build: 'Build teilen',
  view: 'Anzeigen',
  close: 'Schließen',
  price: 'Preis',
  allow: 'Erlauben',
  cancel: 'Abbrechen',
  here: 'Hier',
  ok: 'OK',
  button_start_bld: 'BLD starten',
  message_on_sale: 'Angebot',
  button_buy: 'Kaufen',
  button_start_build: 'Build starten',
  view_build: 'Build anzeigen',
  button_back: 'Zurück',
  performance: 'Leistung',
  build: 'Bauen',
  error_generic: 'Etwas ist falsch gelaufen. Bitte versuche es später erneut.',
  label_support: 'Hilfe',
  contact_support: 'Kontaktiere den Support',
  browse_articles: 'Artikel durchsuchen',
  search_support_articles: 'Nach Hilfeartikeln suchen',
  message_error: 'Ups, etwas ist schiefgelaufen',
  estimated_performance_label: 'Geschätzte Performance',
  see_estimated_performance_label: 'Siehe geschätzte Performance',
  estimated_fps: 'Geschätzte Bilder pro Sekunde (FPS)',
  title_related_items: 'Dir könnte auch gefallen',
  qty: 'STK',
  category_from: 'Von',
  reviews_label: 'Bewertungen',
  message_invalid: 'Momentan nicht verfügbar',
  message_out_of_stock: 'Nicht auf Lager',
  message_low_stock: 'Niedriger Bestand',
  message_best_seller: 'Bestseller',
  message_coming_soon: 'Bald verfügbar',
  message_new_item: 'Neu',
  message_featured_items: 'Ausgewählte Produkte im Bild',
  message_unavailable: 'Nicht verfügbar',
  message_colors: 'Farben',
  message_color: 'Farbe',
  label_return_policy: 'Rücknahmegarantie',
  label_warranty_policy: 'Garantiebestimmungen',
  label_shop: 'Kaufen',
  label_days: 'Tag',
  label_hours: 'Stunden',
  label_minutes: 'Minuten',
  label_seconds: 'Sekunden',
  label_promotion: 'Siehe Aktion',
  label_promotions: 'Werbeaktionen',
  monthly_payments: 'Monatliche Zahlungen verfügbar.',
  message_promotion: 'Förderung',
  discount_label: 'Rabatt',
  off_label: 'Inaktiv',
  on_label: 'Ein',
  choose_label: 'Wählen Sie',
  move_to_previous_label: 'Zur vorherigen Folie wechseln',
  move_to_next_label: 'Zur nächsten Folie wechseln',
  active_slide_label: 'Aktive Folie',
  move_to_inactive_slide: 'Zur inaktiven Folie wechseln',
  color_options: 'Farboptionen',
  of: 'von',
  product_images: 'Produktbilder',
  for: 'für',
  carousel: 'Karussell',
  go_to_image: 'Zum Bild gehen',
  button_label_add: 'Hinzufügen',
  button_label_remove: 'Entfernen',
  increase_price_label: 'Preis erhöhen',
  decrease_price_label: 'Senkung des Preises',
  select_video_label: 'Video auswählen',
  play_label: 'Spielen',
  pause_label: 'Pause',
  buttons: 'Buttons',
  open_modal_label: 'Bildzoom-Modal öffnen',
  close_modal_label: 'Bildzoom-Modal schließen',
  image_zoom_modal: 'Bildzoom-Modal',
  vr_ready_label: 'VR Ready',
  vr_not_ready_label: 'Nicht VR Ready',
  streaming_ready_label: 'Stream Ready',
  streaming_not_ready_label: 'Nicht Stream Ready',
  has_wifi_label: 'Hat WiFi',
  no_wifi_label: 'Kein WiFi',
  has_bluetooth_label: 'Hat Bluetooth',
  no_bluetooth_label: 'Kein Bluetooth',
  has_usbc_label: 'Hat USB-C',
  no_usbc_label: 'Kein USB-C',
  rgb_included_label: 'Inklusive RGB-Beleuchtung',
  rgb_not_included_label: 'RGB-Beleuchtung nicht inbegriffen',
  products_label: 'Produkte',
  upsell_modal_label: 'Häufig zusammen gekauft',
  no_thanks_label: 'Nein danke',
  label_deal_ends: 'Deal endet in',
  label_show_less: 'Weniger anzeigen',
  label_show_more: 'Mehr anzeigen',
  label_case: 'Gehäuse',
  label_software: 'Software',
  label_services: 'Dienstleistungen',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'Grafikkarte',
  label_motherboard: 'Motherboard',
  label_storage: 'Speicher',
  label_cooling: 'Kühlung',
  label_psu: 'Netzteil',
  label_warranty: 'Garantie',
  label_accessories: 'Zubehör',
  label_fps: 'FPS',
  label_feature: 'Feature',
};

const cart = {
  cart_heading: 'Dein Warenkorb',
  cart_heading_empty: 'Dein Warenkorb ist leer',
  cart_subheading_empty: 'Finde Produkte, die du lieben wirst',
  cart_free_shipping_cta: 'Nur noch 25 € bis zum kostenfreien Versand!',
  cart_free_shipping_exclusion:
    '(Schließt benutzerdefinierte und vorgefertigte PCs aus)',
  cart_free_shipping_promo_cta: `Kostenloser Versand für alle Bestellungen`,
  cart_heading_error:
    'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  cart_heading_success: 'Vielen Dank!',
  cart_label_subtotal: 'Zwischensumme',
  cart_label_taxes: 'MwSt',
  cart_value_taxes: 'Wird im nächsten Schritt berechnet',
  cart_label_shipping: 'Versand',
  cart_value_shipping_free: 'KOSTENLOS',
  cart_value_disclaimer:
    '*Neue und/oder überholte Produkte werden gegebenenfalls zu unterschiedlichen Zeiten bearbeitet und versendet.',
  cart_label_total: 'Gesamt',
  cart_button_checkout: 'Weiter zur Kasse',
  cart_terms_message: 'Ich akzeptiere die',
  cart_terms_link_text: 'Geschäftsbedingungen',
  cart_value_taxes_non_us: 'Inklusive',
  cart_value_shipping_calculated: 'Wird im nächsten Schritt berechnet',
  cart_consent_i_have_read: 'Ich habe die',
  cart_consent_privacy_policy: 'Datenschutzbestimmungen',
  cart_consent_privacy_policy_after: 'gelesen',
  cart_au_terms_message: 'Ich akzeptiere die',
  cart_au_terms_link: 'Nutzungsbedingungen',
  cart_au_consent_message:
    'Ich bin einverstanden, dass meine persönlichen Daten gespeichert und in Übereinstimmung mit den',
  cart_au_consent_link: 'Datenschutzbestimmungen genutzt werden',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559) liefert die Güter und Services, die du bestellt hast gemäß ihrer',
  cart_au_terms_text_terms_link: 'Nutzungsbedingungen',
  cart_au_terms_text_2:
    'und speichert, verwendet und veröffentlicht deine persönlichen Informationen in Übereinstimmung mit der',
  cart_au_terms_text_privacy_link: 'Datenschutzerklärung',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'Dienstleistungen',
  cart_label_software: 'Software',
  cart_label_case: 'Gehäuse',
  cart_label_motherboard: 'Motherboard',
  cart_label_cpu: 'CPU',
  cart_label_gpu: 'Grafikkarte',
  cart_label_power_supply: 'Netzteil',
  cart_label_cpu_cooler: 'CPU Kühlung',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'Lüfter',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'Monitore',
  cart_label_peripherals: 'Peripheriegeräte',
  cart_label_extras: 'Extras',
  cart_label_keycaps: 'Tastenkappen',
  cart_label_keycap_accents: 'Tastenkappen-Akzente',
  cart_label_switches: 'Schalter',
  cart_label_keyboard_base: 'Tastaturbasis',
  cart_label_keycap_accent: 'Akzent-Tastenkappen',
  cart_label_accent_cable: 'Akzentkabel',
  cart_label_mouse: 'Maus',
  cart_message_item_fallback: 'Dieser Gegenstand',
  cart_message_build_item_oos_before: 'Ein Artikel in deinem',
  cart_message_build_item_oos_after:
    'ist ausverkauft. Bitte klicken Sie hier, um Ihr Build zu aktualisieren und es in den Warenkorb zu legen.',
  cart_message_oos:
    'ist derzeit nicht auf Lager. Bitte entfernen Sie diesen Artikel, um zur Kasse zu gehen.',
};

const category = {
  explore_builds: 'Builds entdecken',
  explore_bulk: 'Bulk entdecken',
  bulk: 'Bulk',
  refurbished: 'Überholt',
  refurbished_cta:
    'Schau dir unser Angebot von überholten Builds und Komponenten an, die vollständig getestet und inspiziert wurden.',
  bulk_cta:
    'Wir bieten Builds in Großmengen für Gaming-Unternehmen, Universitäten, E-Sport-Organisationen und andere an.',
  explore_refurbished: 'Builds entdecken',
};

const geoipBanner = {
  currently_browsing: 'Gerade angesehen',
  banner_region_text:
    'Wechsle deine Region, um Produkte und spezielle Angebote in deinem Land zu sehen.',
  banner_button: 'Wechseln zu',
  banner_country_text_shop: 'Shop',
  banner_counrty_text:
    'oder wählen Sie ein anderes Land für Ihren Standort aus.',
  country_us: 'Vereinigte Staaten',
  country_canada: 'Kanada',
  country_belgium: 'Belgien',
  country_germany: 'Deutschland',
  country_luxembourg: 'Luxemburg',
  country_netherlands: 'Niederlande',
  country_austria: 'Österreich',
  country_france: 'Frankreich',
  country_italy: 'Italien',
  country_spain: 'Spanien',
  country_uk: 'Vereinigtes Königreich',
  country_australia: 'Australien',
  country_nz: 'Neuseeland',
  country_japan: 'Japan',
  region_na: 'Nordamerika',
  region_europe: 'Europa',
  region_oceania: 'Ozeanien',
};

const ui = {
  loading: 'Laden',
};

const footer = {
  label_language: 'Sprache',
  label_country: 'Land',
  manage_cookie_preferences: 'Cookie-Einstellungen verwalten',
  all_rights_reserved: 'Alle Rechte vorbehalten',
};

const search = {
  label_suggested_queries: 'Empfohlene Abfragen',
  label_suggested_results: 'Empfohlene Ergebnisse',
  label_serp_heading: 'Suchergebnisse für',
  label_serp_not_found: 'Keine Ergebnisse gefunden für',
  label_serp_total: 'Alle Ergebnisse',
  label_serp_suggested: 'Suche lieber nach',
};

const about = {
  label_customer_support_hours: 'Arbeitszeiten des Kunden-Support',
  label_headquarters: 'Hauptsitz',
};

const product = {
  add_to_cart: 'In den Warenkorb',
  overview: 'Übersicht',
  tech_specs: 'Technische Daten',
  downloads: 'Downloads',
  message_size: 'Größe',
  message_series: 'Serie',
  message_invalid: 'Momentan nicht verfügbar',
  message_out_of_stock: 'Nicht auf Lager',
  message_low_stock: 'Geringer Bestand',
  message_new_item: 'Neu',
  message_coming_soon: 'Bald verfügbar',
  message_unavailable: 'Nicht verfügbar',
  title_highlights: 'Highlights',
  title_shipping: 'Versand',
  title_in_the_box: 'Lieferumfang',
  title_related_items: 'Das könnte dir auch gefallen',
  button_buy_now: 'Jetzt kaufen',
  message_colors: 'Farben',
  message_color: 'Farbe',
  overview_anchor: 'Übersicht',
  tech_specs_anchor: 'Technische Daten',
  downloads_anchor: 'Downloads',
  related_anchor: 'Ähnliche Produkte',
  customize_build_header: 'Anpassen',
  customize_build_subheader: 'Starte einen Build mit diesem Produkt',
  customize_kb_build_subheader: 'Erstellen einer eigenen Tastatur',
  buy_product_header: 'DIY',
  buy_product_subheader: 'Träum davon. Builde es. Tu es.',
  buy_label: 'Kaufen',
  retailers_subheader: 'Kauf bei unseren Partnern.',
  link_all_retailers: 'Alle Händler anzeigen',
  series_label: 'Serie',
  label_header_bundle: 'Zusammen kaufen',
  label_subheader_bundle:
    'Bringen Sie Ihren Build mit von Experten empfohlenen Produkten auf die nächste Stufe',
  help_button_label: 'Jetzt chatten',
  help_button_heading: 'Hast du Fragen?',
  help_button_subheading: 'Unsere Experten sind bereit zu helfen.',
  configure_label: 'Konfigurieren',
  documentation_label: 'Dokumente',
  drivers_label: 'Treiber',
  miscellaneous_label: 'Sonstiges',
  resources_label: 'Ressourcen',
  everything_you_need_label: 'Everything you need',
  good_rating_label: 'Gut',
  better_rating_label: 'Besser',
  best_rating_label: 'Am Besten',
  key_specs_label: 'Spezifikationen',
};

const collection = {
  message_invalid: 'Momentan nicht verfügbar',
  message_out_of_stock: 'Nicht auf Lager',
  message_low_stock: 'Geringer Bestand',
  message_unavailable: 'Nicht verfügbar',
  message_new_item: 'Neu',
  message_coming_soon: 'Bald verfügbar',
  button_buy_now: 'Jetzt kaufen',
  message_colors: 'Farben',
  learn_more: 'Mehr erfahren',
  label_show_less: 'Weniger anzeigen',
  label_show_more: 'Mehr anzeigen',
  help_button_label: 'Jetzt chatten',
  help_button_heading: 'Hast du Fragen?',
  help_button_subheading: 'Unsere Experten sind bereit zu helfen.',
};

const news = {
  label_all_news: 'Alle Neuigkeiten',
};

const retailers = {
  label_regions: 'Regionen',
  label_locations: 'Orte',
  label_reseller_type: 'Typ',
  option_label_all_regions: 'Alle Regionen',
  option_label_all_locations: 'Alle Orte',
  option_label_all_reseller_type: 'Alle Optionen',
  message_empty_state: 'Kein Einzelhändler erfüllt die ausgewählten Kriterien.',
  hero_text:
    'NZXT-Produkte sind über unsere Partner in allen Regionen erhältlich',
};

const searchResults = {
  message_invalid: 'Momentan nicht verfügbar',
  message_out_of_stock: 'Nicht auf Lager',
  message_low_stock: 'Geringer Bestand',
  message_unavailable: 'Nicht verfügbar',
  message_coming_soon: 'Bald verfügbar',
};

const orderTracking = {
  title_order_status: 'Bestellstatus',
  label_cancelled_on: 'Storniert am',
  label_shipping_date: 'Voraussichtliches Versanddatum',
  label_case: 'Gehäuse',
  label_software: 'Software',
  label_services: 'Service',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'Grafikkarte',
  label_motherboard: 'Motherboard',
  label_storage: 'Speicher',
  label_cooling: 'Kühlung',
  label_psu: 'Netzteil',
};

const orderTrackingRoot = {
  title_order_tracking_root: 'Gib deine Bestellnummer ein',
  label_build_id: 'Auftragsnummer',
  button_track: 'Bestellung verfolgen',
  message_not_found: 'nicht gefunden, bitte versuche es erneut',
};

const refurb = {
  label_all_products: 'Alle Produkte',
  label_relevance: 'Relevanz',
  label_price_low_high: 'Preis aufsteigend',
  label_price_high_low: 'Preis absteigend',
  label_name_a_z: 'Name A-Z',
  label_name_z_a: 'Name Z-A',
  label_case: 'Gehäuse',
  label_software: 'Software',
  label_services: 'Service',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'Grafikkarte',
  label_motherboard: 'Motherboard',
  label_storage: 'Speicher',
  label_cooling: 'Kühlung',
  label_psu: 'Netzteil',
  label_other: 'Sonstiges',
  label_accessories: 'Zubehör',
  label_fan: 'Lüfter',
  label_any_price: 'Jeder Preis',
  label_no_products: 'Derzeit sind keine überholten Bauteile verfügbar.',
  label_no_builds: 'Derzeit sind keine überholten Builds verfügbar.',
};

const orderStatus = {
  confirmed_title: 'Bestellung aufgegeben',
  step_label: 'Schritt',
  confirmed_sub: 'Deine Bestellung ist eingegangen.',
  processed_title: 'Bestellung bestätigt',
  processed_sub:
    'Deine Bestellung ist eingegangen und deine Zahlung wurde verarbeitet.',
  in_progress_title: 'In Bearbeitung',
  in_progress_sub:
    'Die Kommissionierung und Montage für deine Bestellung hat begonnen. Deine Bestellung ist nun gesperrt und Änderungen/Stornierungen können nicht mehr garantiert werden.',
  shipped_title: 'Versand',
  shipped_sub: 'Deine Bestellung ist unterwegs!',
  cancelled_title: 'Abgesagt',
  cancelled_sub: 'Ihre Bestellung wurde erfolgreich storniert.',
  enh_ordered_sub:
    'Ihre Bestellung ist eingegangen und befindet sich in der Warteschlange.',
  enh_picking_title_bld: 'Build vorbereiten',
  enh_picking_title: 'Bearbeitung Ihrer Bestellung',
  enh_picking_sub_bld:
    'Die Kommissionierung & Montage für Ihre Bestellung hat begonnen. Änderungen an Ihrer Bestellung können nicht garantiert werden.',
  enh_picking_sub:
    'Wir bereiten Ihre Bestellung vor. Änderungen an Ihrer Bestellung können nicht garantiert werden.',
  enh_build_title: 'Gebäude',
  enh_build_sub: 'Ihre Bestellung wird gebaut und getestet.',
  enh_packing_title: 'Vorbereiten, um zu versenden',
  enh_packing_sub_bld:
    'Ihre Bestellung wurde erstellt und getestet. Es wird für die Lieferung verpackt.',
  enh_packing_sub:
    'Ihre Bestellung wurde bearbeitet und wird für die Lieferung verpackt.',
  enh_shipping_title: 'Versand',
  enh_shipping_sub:
    'Ihre Bestellung wurde zur Lieferung an das Versandunternehmen gesendet. Ihre Tracking-Nummer wird aktualisiert und Ihnen innerhalb von 1 Werktag per E-Mail zugesendet.',
};

const universal = {
  ...cart,
  ...userAccount,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const de = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about': {
    ...about,
    ...universal,
  },
  '/about/[slug]': {
    ...about,
    ...universal,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  universal,
};
