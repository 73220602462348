import Snippets, { SnippetArgs } from './snippets';

// inspired by https://github.com/alinemorelli/react-gtm

const GTM = {
  dataScript(dataLayer: string): HTMLElement {
    const scriptEl = document.createElement('script');
    scriptEl.innerHTML = dataLayer;

    return scriptEl;
  },
  gtm(args: SnippetArgs): {
    noScript: () => HTMLElement;
    script: () => HTMLElement;
    dataScript: HTMLElement;
  } {
    const snippets = Snippets.tags(args);

    const noScript = (): HTMLElement => {
      const noscriptEl = document.createElement('noscript');
      noscriptEl.innerHTML = snippets.iframe;

      return noscriptEl;
    };

    const script = (): HTMLElement => {
      const scriptEl = document.createElement('script');
      scriptEl.innerHTML = snippets.script;

      return scriptEl;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize({
    gtmId,
    events = {},
    serverUrl = 'https://www.googletagmanager.com',
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }: {
    gtmId: string;
    events?: Record<string, unknown>;
    serverUrl?: string;
    dataLayer?: Record<string, unknown>;
    dataLayerName?: string;
    preview?: string;
    auth?: string;
  }): void {
    const gtm = this.gtm({
      id: gtmId,
      events,
      serverUrl,
      dataLayer: dataLayer || undefined,
      dataLayerName,
      auth,
      preview,
    });

    if (dataLayer) {
      document.head.appendChild(gtm.dataScript);
    }

    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
  // eslint-disable-next-line consistent-return
  dataLayer({
    dataLayer,
    dataLayerName = 'dataLayer',
  }: {
    dataLayer: Record<string, unknown>;
    dataLayerName?: string;
  }): void {
    if (window[dataLayerName]) {
      return window[dataLayerName].push(dataLayer);
    }

    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);

    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  },
};

export default GTM;
