/* eslint-disable func-names */
/* eslint-disable no-shadow */
/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import { getId, setId } from '@framework/api/utils/heap';
import { SCRIPT_ID } from './constants';

const onAccept = (heapId: string): void => {
  if (typeof window !== 'undefined') {
    if (window.heap && window.heap.loaded === false) {
      window.heap.loaded = true;
    } else {
      (window.heap = window.heap || []),
        (window.heap.load = function (e, t) {
          (window.heap.appid = e),
            (window.heap.config = t = t || { secureCookie: true });
          const r = document.createElement('script');
          (r.type = 'text/javascript'),
            (r.async = !0),
            (r.id = SCRIPT_ID),
            (r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`);
          const a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(r, a);
          for (
            let n = function (error) {
                return function () {
                  if (window.heap && window.heap.push) {
                    window.heap.push(
                      [error].concat(Array.prototype.slice.call(arguments, 0))
                    );
                  }
                };
              },
              p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty',
              ],
              o = 0;
            o < p.length;
            o++
          )
            window.heap[p[o]] = n(p[o]);
        });
      window.heap.load(heapId);

      const id = getId() || setId();

      window.heap.identify(id);
    }
  }
};

export default onAccept;
