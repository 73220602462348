import {
  NECESSARY_COOKIES,
  BRANDING_DEFAULT,
  LABEL_MARKETING,
  DESCRIPTION_MARKETING,
  LABEL_ANALYTICS,
  DESCRIPTION_ANALYTICS,
  CONSENT_COOKIE_EXPIRY,
  TEXT,
  getStatement,
  getLocales,
} from '@constants';
import onLoad, { Config } from './on-load';
import {
  onAccept as gtmOnAccept,
  onRevoke as gtmOnRevoke,
  COOKIES as GTM_COOKIES,
} from './GoogleTagManager';
import {
  onAccept as heapOnAccept,
  onRevoke as heapOnRevoke,
  COOKIES as HEAP_COOKIES,
} from './Heap';
import {
  onAccept as zendeskChatOnAccept,
  onRevoke as zendeskChatOnRevoke,
  COOKIES as ZENDESK_COOKIES,
} from './ZendeskChat';
import {
  onAccept as facebookOnAccept,
  onRevoke as facebookOnRevoke,
  COOKIES as FACEBOOK_COOKIES,
} from './Facebook';
import {
  onAccept as klaviyoOnAccept,
  onRevoke as klaviyoOnRevoke,
  COOKIES as KLAVIYO_COOKIES,
} from './Klaviyo';
import {
  onAccept as KlarnaOnAccept,
  COOKIES as KLARNA_COOKIES,
} from './Klarna';

export type CookieControlOptions = {
  scriptSrc?: string;
  apiKey?: string;
  gtmId?: string;
  gtmServerId?: string;
  heapId?: string;
  facebookId?: string;
  locale?: string;
};

/**
 * NOTE: If adding or removing new cookie(s), please remember to update
 * the STATEMENT_UPDATED constant in cookie-control.ts with the new date.
 */

const startCookieControl = (opts: CookieControlOptions = {}): void => {
  if (!opts.scriptSrc) {
    // eslint-disable-next-line no-console
    console.error('Missing required scriptSrc param for Cookie Control');
  }

  if (!opts.apiKey) {
    // eslint-disable-next-line no-console
    console.error('Missing required apiKey param for Cookie Control');
  }

  if (!opts.gtmId && !opts.heapId && !opts.facebookId) {
    // eslint-disable-next-line no-console
    console.warn('No optional cookies are present in Cookie Control config');
  }

  const config: Config = {
    apiKey: opts.apiKey,
    product: 'PRO',
    position: 'right',
    theme: 'dark',
    initialState: 'notify',
    layout: 'slideout',
    toggleType: 'checkbox',
    statement: getStatement(opts.locale),
    branding: BRANDING_DEFAULT,
    text: TEXT,
    locales: getLocales(opts.locale),
    consentCookieExpiry: CONSENT_COOKIE_EXPIRY,
    necessaryCookies: NECESSARY_COOKIES,
    optionalCookies: [
      opts.gtmId || opts.heapId
        ? {
            name: 'analytics',
            label: LABEL_ANALYTICS,
            recommendedState: true,
            lawfulBasis: 'legitimate interest',
            description: DESCRIPTION_ANALYTICS,
            cookies: [...GTM_COOKIES, ...HEAP_COOKIES, ...ZENDESK_COOKIES],
            onAccept: () => {
              if (opts.gtmId && opts.gtmServerId) {
                gtmOnAccept(opts.gtmId, opts.gtmServerId);
              }

              if (opts.heapId) {
                heapOnAccept(opts.heapId);
              }

              zendeskChatOnAccept();
            },
            onRevoke: () => {
              if (opts.gtmId && opts.gtmServerId) {
                gtmOnRevoke();
              }

              if (opts.heapId) {
                heapOnRevoke();
              }

              zendeskChatOnRevoke();
            },
          }
        : null,
      {
        name: 'marketing',
        label: LABEL_MARKETING,
        recommendedState: true,
        description: DESCRIPTION_MARKETING,
        cookies: [
          ...FACEBOOK_COOKIES,
          ...KLAVIYO_COOKIES,
          ...KLARNA_COOKIES,
          'IDE',
          '__ncuid',
          'u',
        ],
        onAccept: () => {
          facebookOnAccept();
          klaviyoOnAccept(opts.locale);
          KlarnaOnAccept();
        },
        onRevoke: () => {
          facebookOnRevoke();
          klaviyoOnRevoke();
          window.location.reload();
        },
      },
    ].filter(Boolean),
  };

  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.src = opts.scriptSrc;
  scriptEl.onload = () => onLoad(config);

  document.head.appendChild(scriptEl);
};

export default startCookieControl;
