import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  MAXIFY_REGION_EUROPE,
  MAXIFY_REGION_OCEANIA,
  MAXIFY_REGION_UNITED_KINGDOM,
  MAXIFY_REGION_NEW_ZEALAND,
} from './location';

export const KLAVIYO_COMPANY_ID_NA = 'M8RPxA';
export const KLAVIYO_COMPANY_ID_EU = 'Y9kvGQ';
export const KLAVIYO_COMPANY_ID_AU = 'UR6hve';
export const KLAVIYO_COMPANY_ID_UK = 'Y2VZHK';
export const KLAVIYO_COMPANY_ID_NZ = 'SySbg6';

export const KLAVIYO_KEY_NA = 'pk_c9922934b0e628a4b3863c4910a33729c3';
export const KLAVIYO_KEY_EU = 'pk_2e88bcffebc9ac82fc45154803d9f05644';
export const KLAVIYO_KEY_AU = 'pk_9f57791a13da597aaadf65fab3c067a555';
export const KLAVIYO_KEY_UK = 'pk_8a6e7795bd79f250aaf005d79420cc939d';
export const KLAVIYO_KEY_NZ = 'pk_1e8ef6a63b84cfec99c3aa1b3bb991ffa9';

export const KLAVIYO_CREDS_REGION_MAP = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: {
    companyId: KLAVIYO_COMPANY_ID_NA,
    key: KLAVIYO_KEY_NA,
  },
  [MAXIFY_REGION_EUROPE]: {
    companyId: KLAVIYO_COMPANY_ID_EU,
    key: KLAVIYO_KEY_EU,
  },
  [MAXIFY_REGION_OCEANIA]: {
    companyId: KLAVIYO_COMPANY_ID_AU,
    key: KLAVIYO_KEY_AU,
  },
  [MAXIFY_REGION_UNITED_KINGDOM]: {
    companyId: KLAVIYO_COMPANY_ID_UK,
    key: KLAVIYO_KEY_UK,
  },
  [MAXIFY_REGION_NEW_ZEALAND]: {
    companyId: KLAVIYO_COMPANY_ID_NZ,
    key: KLAVIYO_KEY_NZ,
  },
};

export const KLAVIYO_LIST_STOCK_NOTIFICATION = 'TxZ9eX';
export const KLAVIYO_LIST_NEWSLETTER = 'SeahJ8';
export const KLAVIYO_LIST_NEWSLETTER_UK = 'Xr5Gi2';
export const KLAVIYO_LIST_NEWSLETTER_NZ = 'TuYva8';

export const KLAVIYO_LIST_ID_REGION_MAP = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: KLAVIYO_LIST_NEWSLETTER,
  [MAXIFY_REGION_EUROPE]: KLAVIYO_LIST_NEWSLETTER,
  [MAXIFY_REGION_OCEANIA]: KLAVIYO_LIST_NEWSLETTER,
  [MAXIFY_REGION_UNITED_KINGDOM]: KLAVIYO_LIST_NEWSLETTER_UK,
  [MAXIFY_REGION_NEW_ZEALAND]: KLAVIYO_LIST_NEWSLETTER_NZ,
};

export const KLAVIYO_SIGN_UP_COMPANY_ID_REGION_MAP = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: KLAVIYO_KEY_NA,
  [MAXIFY_REGION_EUROPE]: KLAVIYO_KEY_NA,
  [MAXIFY_REGION_OCEANIA]: KLAVIYO_KEY_NA,
  [MAXIFY_REGION_UNITED_KINGDOM]: KLAVIYO_KEY_UK,
  [MAXIFY_REGION_NEW_ZEALAND]: KLAVIYO_KEY_NZ,
};
