/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */

import { getRegion } from '@framework/api/utils/maxify';
import { KLAVIYO_CREDS_REGION_MAP } from '@constants';
import { SCRIPT_SRC } from './constants';

const onAccept = (locale: string): void => {
  if (typeof window !== 'undefined') {
    const scriptEl = document.getElementById('klaviyo');

    if (!scriptEl) {
      const region = getRegion(locale);
      const regionalApiKey = KLAVIYO_CREDS_REGION_MAP[region].companyId;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = SCRIPT_SRC + regionalApiKey;
      script.id = 'klaviyo';

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }

    document.cookie = '__kla_off=false';
  }
};

export default onAccept;
