/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'Account',
  user_settings_change_password: 'Change Password',
  user_settings_save: 'Save',
  user_settings_cancel: 'Cancel',
  user_profile_heading: 'Profile',
  user_saved_builds_heading: 'Saved Builds',
  user_saved_builds_bld_name: 'Build name',
  user_saved_builds_bld_date: 'Date created',
  user_saved_builds_bld_item_part: 'Part',
  user_saved_builds_delete_bld: 'Delete BLD',
  user_saved_builds_resume_bld: 'Resume BLD',
  user_saved_builds_hero_title: 'Designed to Show Off Your Build',
  user_saved_builds_hero_text:
    'The compact vertical design maintains support for most full-sized GPU’s on the market so you can maintain your desk real estate without sacrificing graphics power.',
  user_saved_builds_pc_heading: 'PC Builds',
  user_saved_builds_keyboard_heading: 'Keyboard Builds',
  user_saved_builds_view_pc: 'View PC Builds',
  user_saved_builds_view_keyboard: 'View Keyboard Builds',
  user_saved_builds_no_pc: 'You have no saved PC Builds',
  user_saved_builds_no_kb: 'You have no saved Keyboard Builds',
  user_order_history_heading: 'Order History',
  user_order_history_date: 'Date',
  user_order_history_order_number: 'Order Number',
  user_order_history_status: 'Status',
  user_order_history_amount: 'Amount',
  user_order_history_view: 'View receipt',
  user_order_history_empty_view: 'You have no order history.',
  user_order_history_shipping: 'Shipping Address',
  user_order_history_billing: 'Billing Address',
  user_order_history_item: 'Item',
  user_order_history_item_image: 'Item image',
  user_order_history_quantity: 'Quantity',
  user_order_history_order: 'Order #',
  user_support_heading: 'Support + Returns',
  user_data_heading: 'Data Request',
  user_terms_heading: 'Terms',
  user_terms_empty: 'You have no signed terms yet.',
  user_support_subject_min_length: 'Subject must be at least 3 characters',
  user_support_message_placeholder:
    'Please detail reasons for contact. If return, please include order number',
  user_support_update_button: 'Update',
  user_active_tickets_heading: 'Active Tickets',
  user_active_tickets_chat: 'Add a message',
  user_active_tickets_no_chat_history: 'You have no chat history',
  view_chat_button: 'View Chat',
  close_chat_button: 'Close Chat',
  field_required_message: 'This field is required',
  label_accept: 'Accept',
  label_reason_for_contact: 'Reason for Contact',
  label_country: 'Country',
  label_subject: 'Subject',
  label_product: 'Product',
  label_message: 'Message',
  label_requestor_type: 'Requestor Type',
  label_country_of_residence: 'Country of Residence',
  label_description: 'Description',
  placeholder_description: 'Description of your request',
  label_submit: 'Submit',
  message_no_support_requests: 'You have no active support requests',
  label_ticket_number: 'Ticket Number',
  label_date_created: 'Date Created',
  label_status: 'Status',
  label_department: 'Department',
  subheading_active_ticket:
    'If you require any help or are looking to return an item, please contact support.',
  label_create_support_request: 'Create Support Request',
  button_comment: 'Comment',
  label_for_myself: 'For Myself',
  label_parent: 'Parent',
  label_legal_rep: 'Legal Representative',
  label_relative: 'Relative',
  label_guardian: 'Guardian',
  label_accept_generic_terms: 'I have read and accept these terms',
  error_view_message: 'Oops! Something went wrong. Please try again.',
  signout_view_message: 'You have been logged out.',
  birthday_error_message: 'Please enter a valid birth date',
  label_view_saved: 'View all saved builds',
};

const formErrors = {
  invalid_email: 'Please enter a valid email',
  username_full_pattern_message:
    'Username must be 6 to 30 characters long. You can use letters, numbers and periods but cannot start, end or contain consecutive periods.',
  password_pattern_message:
    'Password must contain at least six characters, one uppercase letter, one lowercase letter and one number',
  username_min_length_message: 'Use 6 characters or more for your username',
  passwords_must_match: 'Passwords must match',
  invalid_current_password:
    'Unable to update the password. The value provided as the current password is incorrect',
  must_accept_partner_program_terms:
    'You must accept the Partner Program Terms to become an NZXT partner',
  must_accept_tos_terms:
    'You must accept the Terms of Service to create a NZXT Account',
  must_accept_generic_terms: 'You must agree to these terms',
};

const auth = {
  user_auth_agreement: "I accept the NZXT's",
  user_auth_privacy_policy: 'Privacy Policy',
  user_auth_and: 'and have read the',
  user_auth_terms: 'Terms of Use',
  error_invalid_request: 'Invalid request.',
  error_invalid_token: 'Invalid user authentication token',
  error_session_expired:
    'Your session has expired. Please login again to continue.',
  user_forgot_required_email: 'Email address is required',
  user_forgot_success_message:
    "Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.",
  error_email_exists: 'The email is already in use',
  user_email_verification_heading: 'Email Verification',
  error_email_not_verified:
    'The Email address is not yet verified. To resend the verification email, click',
  error_email_already_verified:
    'The Email address has already been verified. Please continue to login.',
  user_email_verification_success_message:
    'Your Email address has been verified successfully!. Please continue to login.',
  error_username_unavailable: 'The username is unavailable',
  error_reset_password_same_as_old_password:
    'This password is the same as before. Please select another.',
  error_reset_password_link_expired:
    'The link to reset your password has expired.',
  error_reset_password_link_invalid:
    'The link to reset your password is invalid.',
  user_forgot_password_subheading:
    'Enter your email to receive instructions on how to reset your password.',
  user_reset_password_heading: 'Reset your password',
  user_reset_password_subheading: 'Please enter a new password.',
  user_reset_password: 'Reset password',
  user_reset_success_message: 'You have successfully reset your password!',
  user_login_text: 'Sign in with Google',
  user_login_remember: 'Remember me',
  user_login_forgot_password: 'Forgot password?',
  user_login_wrong_email_or_password: 'Incorrect email address or password',
  user_login_success_message: 'You have successfully logged in!',
  error_too_many_requests:
    'You have made too many requests. Please wait a few minutes or contact support',
  error_user_not_found: 'User was not found',
  user_sign_in_sign_up: 'Sign In / Sign Up',
  user_sign_out: 'Sign Out',
  user_sign_in: 'Sign In',
  user_account_updates: 'NZXT Updates',
  user_account_no_updates: 'No recent updates, check back later.',
  support_center_link: 'Support Center',
  error_google_account_exists:
    'You already created an account using Google. Please login instead.',
  error_local_account_exists:
    'Looks like you already have an account. Please login instead.',
  user_sign_up: 'Sign up',
  user_sign_up_text: 'Sign up with Google',
  user_sign_up_opt_in:
    'Sign up for emails to get updates from NZXT on products, BLD, and CAM',
  user_sign_up_check_email_message:
    'Check your email and click the link to activate your account.',
  user_sign_up_success_verify_message:
    'The account will remain inactive until verified. Please check your spam folder if you do not receive the email within a few minutes.',
  user_info_updated_success_message:
    'Your information has been updated successfully!',
  user_info_newsletter_confirmation_message:
    'Please check your email for confirmation.',
  user_email_sign_in: 'Or continue with',
  user_no_account: 'Not a member?',
  user_create_account: 'Create account',
  user_email_sign_up_text: 'Or sign up with',
  user_have_account: 'Have an account?',
  open_login_view: 'Open login view',
  error_newsletter_subscription:
    'Unable to subscribe/unsubscribe to newsletter at the moment. Please try again later.',
  label_email_address: 'Email Address',
  label_password: 'Password',
  label_current_password: 'Current Password',
  label_confirm_password: 'Confirm Password',
  label_new_password: 'New Password',
  label_confirm_new_password: 'Confirm New Password',
  label_username: 'Username',
  label_first_name: 'First Name',
  label_last_name: 'Last Name',
  label_preferred_pronouns: 'Preferred pronouns',
  label_search_for_help_articles: 'Search for help articles',
  label_birthday: 'Birthday',
  label_location: 'Location',
  app_access: 'wants access to your NZXT account to access:',
  app_access_has_scopes: 'wants access to your NZXT account',
  scope_profile: 'Your profile, including your name and image',
  scope_email: 'Your email address',
  scope_address: 'Your physical address',
  scope_phone: 'Your phone number',
  grant_offline_access: 'Have offline access',
  grant_offline_access_previous: "which you've previously granted",
};

const build = {
  custom_bld_modal_heading: 'Build your PC',
  custom_bld_modal_subheading: 'Select your chipset and budget',
  custom_bld_unavailable: 'Please select a different item',
  custom_bld_issue: 'The following item(s) need resolving: ',
  custom_bld_title: 'Custom BLD',
  custom_bld_lets_get_started: `Let's get started`,
  custom_bld_destrutive_action: `Warning: Build Reset`,
  custom_bld_confirm_reset: `Your build will be reset with the following chipset:`,
  custom_bld_keyboard_confirm_reset: `Your build will be reset with this change`,
  custom_bld_choose_chipset: 'Choose your Chipset',
  custom_bld_chipset: 'Chipset',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Explore',
  custom_bld_select: 'Select',
  review_bld_button: 'Review BLD',
  custom_bld_summary: 'Summary',
  custom_bld_budget: 'Budget',
  custom_bld_specs: 'Specs',
  custom_bld_case: 'Case',
  custom_bld_cases: 'Cases',
  custom_bld_caseCooling: 'Cooling',
  custom_bld_fans: 'Fans',
  custom_bld_cpu: 'CPU',
  custom_bld_gpu: 'GPU',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'HDD',
  custom_bld_specials: 'Specials',
  custom_bld_colors: 'Colors',
  custom_bld_color: 'Color',
  custom_bld_black: 'Black',
  custom_bld_red: 'Red',
  custom_bld_white: 'White',
  custom_bld_grey: 'Grey',
  custom_bld_blue: 'Blue',
  custom_bld_purple: 'Purple',
  custom_bld_yellow: 'Yellow',
  custom_bld_audio: 'Audio',
  custom_bld_extras: 'Extras',
  custom_bld_core: 'Core',
  custom_bld_motherboard: 'Motherboard',
  custom_bld_motherboards: 'Motherboards',
  custom_bld_powerSupplies: 'Power Supplies',
  custom_bld_powerSupply: 'Power Supply',
  custom_bld_services: 'Services',
  custom_bld_software: 'Software',
  custom_bld_monitors: 'Monitors',
  custom_bld_peripherals: 'Peripherals',
  custom_bld_lighting: 'Lighting',
  custom_bld_headset: 'Headset',
  custom_bld_keyboard: 'Keyboard',
  custom_bld_all: 'All',
  custom_bld_mouse: 'Mouse',
  custom_bld_storage: 'Storage',
  custom_bld_streaming_ready: 'Stream Ready',
  custom_bld_rgb_lighting: 'RGB Lighting',
  custom_bld_vr_ready: 'VR Ready',
  custom_bld_wifi: 'WiFi',
  custom_bld_from: 'From',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    'Your build has been saved successfully! You can view it in "Saved Builds" in your Account.',
  custom_bld_save_modal_message: 'Do you want to save this build for later?',
  custom_bld_confirm_save_modal_message:
    'You will lose unsaved changes by leaving this page.',
  custom_bld_share_build_message:
    'Your build has been saved and copied successfully!',
  help_me_decide: 'Help me decide',
  save_build: 'Save Build',
  start_over: 'Start Over',
  summary: 'Summary',
  build_summary: 'Build Summary',
  load_build: 'Load Build',
  load: 'Load',
  save: 'Save',
  restart: 'Restart',
  share: 'Share',
  my_build_label: 'My Build',
  est_shipping: 'Estimated Shipping',
  add_to_build: 'Add to Build',
  message_invalid: 'Temporarily unavailable',
  message_out_of_stock: 'Sold Out',
  message_low_stock: 'Low Stock',
  message_new_item: 'New',
  message_coming_soon: 'Coming Soon',
  message_best_seller: 'Best Seller',
  message_is_locked: 'Not Compatible',
  button_label_select: 'Select',
  button_label_selected: 'Selected',
  button_label_add: 'Add',
  button_label_added: 'Added',
  button_label_update: 'Update',
  button_label_remove: 'Remove',
  button_label_info: 'Info',
  button_label_more_info: 'More Info',
  button_label_options_info: 'Options & Info',
  button_label_continue_to: 'Continue to Build',
  button_label_edit: 'Edit',
  build_fps_label: `FPS Speeds are measured at 2560x1440 resolution using each game's highest graphic setting`,
  build_includes: 'Includes',
  custom_bld_boost: 'Boost your Build',
  custom_bld_fps_unavailable: 'Pending',
  custom_bld_amd_help:
    'Intel based PCs have been the norm for the last few years but AMD PCs are amazing for gaming now. We’ll help you build for both and we’ve picked motherboards and coolers that work well together so that you don’t have to figure it out. We recommend balancing the FPS performance that we show with builds with what you’re willing to budget for it.',
  custom_bld_to_see_this: `To see this selection you'll have to`,
  custom_bld_change_chipset: `change your chipset.`,
  custom_bld_prebuild_upsell: 'Or pick quick and easy prebuilds',
  no_item_selected: 'No Item Selected',
  build_change_label: 'Build Modified',
  invalid_action_label: 'Prevented',
  success_label: 'Success',
  build_info_message: 'Info',
  reset_label: 'Build Reset',
  complete_build_label: 'Complete Your Build',
  back_to_build_label: 'Back to Your Build',
  complete_build_sub_label: 'Extras & Peripherals',
  back_to_build_sub_label: 'Core components of your build',
  capacity_label: 'Capacity',
  option_label: 'Option',
  options_label: 'Options',
  max_speed: 'Max Speed',
  modular_label: 'Modular',
  midtower_label: 'Mid-Tower',
  streaming_label: 'Streaming',
  save_and_exit: 'Save and Exit',
  exit: 'Exit',
  view_cart_label: 'View Cart',
  cart_subtotal_label: 'Cart Subtotal',
  start_build_label: 'Start Build',
  new_build_label: 'New Build',
  continue_saved_build_label: 'Continue your saved build',
  continue_progress_build_label: 'Continue the build you have in progress',
  continue_cart_build_label: 'Review build in cart',
  core_label: 'Base',
  extras_label: 'Add-ons',
  summary_label: 'Summary',
  share_build_pc: 'Custom build PC',
  share_build_kb: 'Custom build Keyboard',
  build_summary_label: 'Overview of your keyboard build.',
  pc_hub_card_heading: 'Custom Gaming PCs',
  pc_hub_card_subheading:
    'Build your dream PC around your budget and optimized for the games you love.',
  kb_hub_card_heading: 'Custom Keyboards',
  kb_hub_card_subheading:
    'Build a custom keyboard and mouse to match your space and game style',
};

const keyboard = {
  keyboard_label: 'Keyboard',
  keyboard_size: 'Keyboard Size',
  keyboard_base: 'Keyboard Base',
  keyboard_primay_base_color: 'Base Color',
  keyboard_layout: 'Keyboard Layout',
  keyboard_summary: 'Keyboard Summary',
  accent_keycaps: 'Accent Keycaps',
  cabletype: 'Accent Cable',
  additional_cable_color: 'Cable Color',
  cable: 'Cable',
  additional_cable: 'Additional Cable',
  primary_keycaps: 'Base Keycaps',
  switches: 'Switches',
  size: 'Size',
  layout: 'Layout',
  language: 'Language',
  mouse_pad_label: 'Mouse Pad',
  desk_mat_label: 'Desk Mat',
  tools_label: 'Tools',
  wrist_rest_label: 'Wrist Rest',
  mouse_label: 'Mouse',
  cable_description:
    'Optional colored cable. A standard black 2-meter cable will be included.',
  cable_color_description: 'The color of the premium cable.',
  language_description: 'Determines the position of Q, W, Z and A keys.',
  layout_description: 'Determines the size and position of the keys.',
  keycap_accent_description:
    'Optional color for Escape, Enter, Arrow, and Numpad Enter keys.',
  primary_keycap_description: 'PBT dye-sublimated, Cherry profile.',
  switch_description: 'Defines the typing experience of each key press.',
  keyboard_base_color_description: 'The main color of the keyboard base.',
  keyboard_base_size_description:
    'Size determines the number of keys and dimension of the keyboard.',
  mouse_description: 'Lightweight ambidextrous mouse.',
  summary_description: 'Overview of your keyboard build',
  premium_label: 'Premium',
  base_label: 'Base',
  keyboard_warning:
    'Please select the following items to proceed with your build:',
  switch_reset_warning:
    'Your switches have been reset. Please select new switches.',
  keycap_reset_warning:
    'Your keycaps have been reset. Please select new keycaps.',
  base_type: 'Build Type',
  build_type_description: `Select the type of keyboard you'd like to configure`,
  full_custom_label: 'Full Custom',
  barebones_disclaimer: 'Change your Build Type to select this item',
  none_label: 'None',
};

const camCta = {
  cam_description:
    'Manage performance, temperatures, and devices all from a single application.',
  cam_windows_10: 'WINDOWS 10 64BIT',
};

const helpCta = {
  help_description:
    'Get extensive guides and support for all your NZXT products as well as help for warranties, return and anything technical',
};

const emailSignUp = {
  email_stay_in_touch: 'Stay In Touch',
  email_sub_copy:
    'Keep up to date on the latest releases, offers, and news from NZXT',
  email_legal: `By signing up you agree to NZXT, CAM, and BLD's `,
  email_notify: 'Notify Me',
  email_notify_stock: 'Get notified when this item is available.',
};

const generic = {
  explore: 'Explore',
  cam_title: 'NZXT CAM',
  free_download: 'Free Download',
  details: 'Details',
  help_amp_support: 'Help & Support',
  help_and_support: 'Help and Support',
  read_more: 'Read More',
  privacy_policy: 'Privacy Policy',
  terms_conditions: 'Terms & Conditions',
  and: 'and',
  or: 'or',
  send: 'send',
  return_to: 'return to',
  previous: 'Previous',
  next: 'Next',
  home: 'Home',
  keep_browsing: 'Keep Browsing',
  create_build: 'Create Build',
  learn_more: 'Learn More',
  add_to_cart: 'Add to Cart',
  share_build: 'Share Build',
  view: 'View',
  close: 'Close',
  price: 'Price',
  allow: 'Allow',
  cancel: 'Cancel',
  here: 'here',
  ok: 'Ok',
  button_start_bld: 'Start BLD',
  message_on_sale: 'Sale',
  button_buy: 'Buy',
  button_start_build: 'Start Build',
  view_build: 'View Build',
  button_back: 'Back',
  build: 'Build',
  performance: 'Performance',
  error_generic: 'Something Went Wrong, Please try again later.',
  label_support: 'Support',
  contact_support: 'Contact Support',
  browse_articles: 'Browse Articles',
  search_support_articles: 'Search for help articles',
  message_error: 'Uh-oh, looks like something went wrong',
  estimated_performance_label: 'Performance',
  see_estimated_performance_label: 'See Estimated Performance',
  estimated_fps: 'Estimated frames per second (FPS)',
  title_related_items: 'You may also like',
  qty: 'QTY',
  category_from: 'From',
  reviews_label: 'Reviews',
  message_out_of_stock: 'Sold Out',
  message_low_stock: 'Low Stock',
  message_new_item: 'New',
  message_coming_soon: 'Coming Soon',
  message_best_seller: 'Best Seller',
  message_unavailable: 'Unavailable',
  message_invalid: 'Temporarily unavailable',
  message_featured_items: 'Featured Products in Image',
  message_colors: 'Colors',
  message_color: 'Color',
  label_return_policy: 'Return Policy',
  label_warranty_policy: 'Warranty Policy',
  label_shop: 'Shop',
  label_days: 'Days',
  label_hours: 'Hours',
  label_minutes: 'Minutes',
  label_seconds: 'Seconds',
  label_promotion: 'See Promotion',
  label_promotions: 'Promotions',
  monthly_payments: 'Monthly payments available.',
  message_promotion: 'Promotion',
  discount_label: 'Off',
  off_label: 'Off',
  on_label: 'On',
  choose_label: 'Choose',
  move_to_previous_label: 'Move to previous slide',
  move_to_next_label: 'Move to next slide',
  active_slide_label: 'Active Slide',
  move_to_inactive_slide: 'Move to inactive slide',
  color_options: 'Color Options',
  of: 'of',
  product_images: 'Product images',
  for: 'for',
  carousel: 'Carousel',
  go_to_image: 'Go to image',
  button_label_add: 'Add',
  button_label_remove: 'Remove',
  increase_price_label: 'Increase Price',
  decrease_price_label: 'Decrease Price',
  select_video_label: 'Select Video',
  play_label: 'Play',
  pause_label: 'Pause',
  buttons: 'Buttons',
  open_modal_label: 'Open Image Zoom Modal',
  close_modal_label: 'Close Image Zoom Modal',
  image_zoom_modal: 'Image Zoom Modal',
  vr_ready_label: 'VR Ready',
  vr_not_ready_label: 'Not VR Ready',
  streaming_ready_label: 'Stream Ready',
  streaming_not_ready_label: 'Not Stream Ready',
  has_wifi_label: 'Has WiFi',
  no_wifi_label: 'No WiFi',
  has_bluetooth_label: 'Has Bluetooth',
  no_bluetooth_label: 'No Bluetooth',
  has_usbc_label: 'has USB-C',
  no_usbc_label: 'No USB-C',
  rgb_included_label: 'RGB Lighting Included',
  rgb_not_included_label: 'RGB Lighting Not Included',
  products_label: 'Products',
  upsell_modal_label: 'Frequently bought together',
  no_thanks_label: 'No Thanks',
  label_deal_ends: 'Deal ends in',
  label_show_more: 'Show More',
  label_show_less: 'Show Less',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Services',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Motherboard',
  label_storage: 'Storage',
  label_cooling: 'Cooling',
  label_psu: 'PSU',
  label_warranty: 'Warranty',
  label_accessories: 'Accessories',
  label_fps: 'FPS',
  label_feature: 'Feature',
};

const cart = {
  cart_heading: 'Your Cart',
  cart_heading_empty: 'Your cart is empty',
  cart_subheading_empty: `Find products you'll love`,
  cart_free_shipping_cta: `You're $25 away from free shipping!`,
  cart_free_shipping_exclusion: '(Excludes Custom and Prebuilt PCs)',
  cart_free_shipping_promo_cta: `Free shipping on all orders`,
  cart_heading_error: 'Something went wrong. Please try again.',
  cart_heading_success: 'Thank you!',
  cart_label_subtotal: 'Subtotal',
  cart_label_taxes: 'Taxes',
  cart_value_taxes: 'Calculated at checkout',
  cart_label_shipping: 'Estimated shipping',
  cart_value_shipping_free: 'FREE',
  cart_value_disclaimer:
    '*New and/or refurbished products may process and ship at different times.',
  cart_label_total: 'Total',
  cart_button_checkout: 'Proceed to Checkout',
  cart_terms_message: 'I accept the',
  cart_terms_link_text: 'terms and conditions of service',
  cart_value_taxes_non_us: 'Included',
  cart_value_shipping_calculated: 'Calculated at checkout',
  cart_consent_i_have_read: 'I have read the',
  cart_consent_privacy_policy: 'privacy policy',
  cart_consent_privacy_policy_after: ' ',
  cart_au_terms_message: 'I accept the',
  cart_au_terms_link: 'Terms of Service',
  cart_au_consent_message:
    'I consent to my personal information being collected and used in accordance with the',
  cart_au_consent_link: 'Privacy Policy',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559) supplies the goods and services you have ordered pursuant to its',
  cart_au_terms_text_terms_link: 'Terms and Conditions',
  cart_au_terms_text_2:
    'and collects, uses, and discloses the personal information it collects from you in accordance with its',
  cart_au_terms_text_privacy_link: 'Privacy Policy',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'Service',
  cart_label_software: 'Software',
  cart_label_case: 'Case',
  cart_label_motherboard: 'Motherboard',
  cart_label_cpu: 'CPU',
  cart_label_gpu: 'GPU',
  cart_label_power_supply: 'Power Supply',
  cart_label_cpu_cooler: 'CPU Cooler',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'Fans',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'Monitors',
  cart_label_peripherals: 'Peripherals',
  cart_label_extras: 'Extras',
  cart_label_keycaps: 'Keycaps',
  cart_label_keycap_accents: 'Keycap Accents',
  cart_label_switches: 'Switches',
  cart_label_keyboard_base: 'Keyboard Base',
  cart_label_keycap_accent: 'Accent Keycaps',
  cart_label_accent_cable: 'Accent Cable',
  cart_label_mouse: 'Mouse',
  cart_message_item_fallback: 'This item',
  cart_message_build_item_oos_before: 'An item in your',
  cart_message_build_item_oos_after:
    'has sold out. Please click here to update your build in order to add it to cart.',
  cart_message_oos:
    'is currently out of stock.  Please remove this item to proceed to checkout.',
};

const category = {
  explore_refurbished: 'Explore Builds',
  explore_bulk: 'Explore Bulk',
  bulk: 'Bulk',
  refurbished: 'Refurbished',
  refurbished_cta:
    'Check out deals on our refurbished builds and parts that are fully tested and inspected.',
  bulk_cta:
    'We’ve helped provide builds in bulk to gaming companies, universities, esport organizations and more.',
};

const geoipBanner = {
  currently_browsing: 'Currently browsing',
  banner_region_text:
    'Switch your region to shop and see content specific to your location.',
  banner_button: 'Switch to',
  banner_country_text_shop: 'Shop',
  banner_counrty_text:
    'or select a different country specific to your location.',
  country_us: 'United States',
  country_canada: 'Canada',
  country_belgium: 'Belgium',
  country_germany: 'Germany',
  country_luxembourg: 'Luxembourg',
  country_netherlands: 'Netherlands',
  country_austria: 'Austria',
  country_france: 'France',
  country_italy: 'Italy',
  country_spain: 'Spain',
  country_uk: 'United Kingdom',
  country_australia: 'Australia',
  country_nz: 'New Zealand',
  country_japan: 'Japan',
  region_na: 'North America',
  region_europe: 'Europe',
  region_oceania: 'Oceania',
};

const ui = {
  loading: 'Loading',
};

const footer = {
  label_language: 'Language',
  label_country: 'Country',
  manage_cookie_preferences: 'Manage Cookie Preferences',
  all_rights_reserved: 'All Rights Reserved',
};

const search = {
  label_suggested_queries: 'Suggested Queries',
  label_suggested_results: 'Suggested Results',
  label_serp_heading: 'Search results for',
  label_serp_not_found: 'No results found for',
  label_serp_total: 'total results',
  label_serp_suggested: 'Try searching for',
};

const about = {
  label_customer_support_hours: 'Customer Support Hours',
  label_headquarters: 'Headquarters',
};

const product = {
  overview: 'Overview',
  tech_specs: 'Tech Specs',
  downloads: 'Downloads',
  message_invalid: 'Temporarily unavailable',
  message_out_of_stock: 'Sold Out',
  message_low_stock: 'Low Stock',
  message_new_item: 'New',
  message_coming_soon: 'Coming Soon',
  message_unavailable: 'Unavailable',
  title_highlights: 'Highlights',
  title_shipping: 'Shipping',
  title_in_the_box: 'Inside the Box',
  button_buy_now: 'Buy Now',
  message_colors: 'Colors',
  message_color: 'Color',
  message_size: 'Size',
  message_series: 'Series',
  overview_anchor: 'Overview',
  tech_specs_anchor: 'Tech Specs',
  downloads_anchor: 'Downloads',
  related_anchor: 'Related',
  customize_build_header: 'Or Build',
  customize_build_subheader: 'Build a custom PC with this item',
  customize_kb_build_subheader: 'Build a custom keyboard',
  buy_product_header: 'DIY',
  buy_product_subheader: 'Dream it. Build it. Do it.',
  buy_label: 'Buy',
  retailers_subheader: 'Shop with our partners.',
  link_all_retailers: 'View all retailers',
  series_label: 'Series',
  label_header_bundle: 'Buy Together',
  label_subheader_bundle:
    'Take your build to the next level with expert recommended products',
  help_button_label: 'Chat Now',
  help_button_heading: 'Have questions?',
  help_button_subheading: 'Our experts are ready to help.',
  configure_label: 'Configure',
  documentation_label: 'Documentation',
  drivers_label: 'Drivers',
  miscellaneous_label: 'Miscellaneous',
  resources_label: 'Resources',
  everything_you_need_label: 'Everything you need',
  good_rating_label: 'Good',
  better_rating_label: 'Better',
  best_rating_label: 'Best',
  key_specs_label: 'Key Specs',
};

const collection = {
  message_invalid: 'Temporarily unavailable',
  message_out_of_stock: 'Sold Out',
  message_low_stock: 'Low Stock',
  message_unavailable: 'Unavailable',
  message_new_item: 'New',
  message_coming_soon: 'Coming Soon',
  button_buy_now: 'Buy Now',
  message_colors: 'Colors',
  learn_more: 'Learn More',
  help_button_label: 'Chat Now',
  help_button_heading: 'Have questions?',
  help_button_subheading: 'Our experts are ready to help.',
};

const news = {
  label_all_news: 'All News',
};

const retailers = {
  label_regions: 'Regions',
  label_locations: 'Locations',
  label_reseller_type: 'Type',
  option_label_all_regions: 'All Regions',
  option_label_all_locations: 'All Locations',
  option_label_all_reseller_type: 'All Options',
  message_empty_state: 'No retailers match selected criteria.',
  hero_text:
    'NZXT products can be purchased through our partners in all regions',
};

const searchResults = {
  message_invalid: 'Temporarily unavailable',
  message_out_of_stock: 'Sold Out',
  message_low_stock: 'Low Stock',
  message_new_item: 'New',
  message_coming_soon: 'Coming Soon',
  message_unavailable: 'Unavailable',
};

const orderTracking = {
  title_order_status: 'Order Status',
  label_cancelled_on: 'Cancelled On',
  label_shipping_date: 'Estimated Shipping Date',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Services',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Motherboard',
  label_storage: 'Storage',
  label_cooling: 'Cooling',
  label_psu: 'PSU',
};

const orderTrackingRoot = {
  title_order_tracking_root: 'Enter your order ID',
  label_build_id: 'Order ID',
  button_track: 'Track Order',
  message_not_found: 'not found, please try again',
};

const refurb = {
  label_all_products: 'All Products',
  label_relevance: 'Relevance',
  label_price_low_high: 'Price Low to High',
  label_price_high_low: 'Price High to Low',
  label_name_a_z: 'Name A-Z',
  label_name_z_a: 'Name Z-A',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Services',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Motherboard',
  label_storage: 'Storage',
  label_cooling: 'Cooling',
  label_psu: 'PSU',
  label_other: 'Other',
  label_accessories: 'Accessories',
  label_fan: 'Fans',
  label_any_price: 'Any Price',
  label_no_products:
    'There are no refurbished components available at this time.',
  label_no_builds: 'There are no refurbished builds available at this time.',
};

const orderStatus = {
  confirmed_title: 'Order Placed',
  step_label: 'Step',
  confirmed_sub: 'Your order has been received.',
  processed_title: 'Order Confirmed',
  processed_sub:
    'Your order has been received and your payment has been processed.',
  in_progress_title: 'In Progress',
  in_progress_sub:
    'Picking & assembly for your order has begun. Your order is now locked and changes/cancellations cannot be guaranteed.',
  shipped_title: 'Shipped',
  shipped_sub: 'Your order is on its way!',
  cancelled_title: 'Cancelled',
  cancelled_sub: 'Your order has been successfully cancelled.',
  enh_ordered_sub: 'Your order has been received and is in queue.',
  enh_picking_title_bld: 'Preparing Build',
  enh_picking_title: 'Processing Your Order',
  enh_picking_sub_bld:
    'Picking & assembly for your order has begun. Modifications to your order cannot be guaranteed.',
  enh_picking_sub:
    'We are preparing your order. Modifications to your order cannot be guaranteed.',
  enh_build_title: 'Building',
  enh_build_sub: 'Your order is being built and tested.',
  enh_packing_title: 'Preparing to Ship',
  enh_packing_sub_bld:
    'Your order has been built and tested. It is being packed for delivery.',
  enh_packing_sub:
    'Your order has been processed and is being packed for delivery.',
  enh_shipping_title: 'Shipping',
  enh_shipping_sub:
    'Your order has been sent to the shipping carrier for delivery. Your tracking number will be updated and emailed to you within 1 business day.',
};

const universal = {
  ...cart,
  ...userAccount,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const en = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about': {
    ...universal,
    ...about,
  },
  '/about/[slug]': {
    ...universal,
    ...about,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  '/oauth/[slug]': {
    ...universal,
  },
  universal,
};
