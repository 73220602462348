/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import { useEffect, FC, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import useBuildStore, {
  getSetPrevPath,
  getSetCurrentPath,
  getCurrentPath,
} from '@stores/use-build-store';
import parseLocale from '@utils/parse-locale';
import detectAdblock from '@utils/detect-adblock';
import { CommerceProvider } from '@framework';
import { useLocationBasedCookies } from '@utils/hooks';
import CookieControl from '@components/CookieControl';
import { SEO_DEFAULTS, DEFAULT_LOCALE, ROUTE_BUILD } from '@constants';
import * as locales from '@locale';
import '@css/fonts.css';
import '@css/index.css';
import ExtendConfig from '@components/Extend/ExtendConfig';
import InitializeAftermarket from '@components/Extend/ExtendAfterMarket';

NProgress.configure({ showSpinner: false });

const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
const cookieControlId = process.env.NEXT_PUBLIC_COOKIE_CONTROL_ID;
const cookieControlSrc = process.env.NEXT_PUBLIC_COOKIE_CONTROL_SRC;
const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
const gtmServerId = process.env.NEXT_PUBLIC_SSGTM_URL;
const heapId = process.env.NEXT_PUBLIC_HEAP_ID;

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  Layout?: React.ElementType;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const Noop: FC<{ children?: ReactNode }> = ({ children }) => <>{children}</>;

const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps, ...rest }) => {
  const router = useRouter();
  const { locale, defaultLocale, pathname } = router;
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0];

  const {
    locationBasedCookies: { cartCookie, localeCookie, shopifyCartCookie },
    updateLocationBasedCookies,
  } = useLocationBasedCookies({ locale, defaultLocale });

  // eslint-disable-next-line import/namespace
  const localeCopy = locales[lang];
  const messages = localeCopy[pathname] || localeCopy.universal;

  const Layout = Component.Layout || Noop;

  // detect adblock
  useEffect(() => {
    async function detect(): Promise<void> {
      const usingBlocker = await detectAdblock();
      window.blocker = usingBlocker;
    }

    detect();
  }, []);

  useEffect(() => {
    const splitUrl = window.location.pathname.split('/');
    const regex = /[a-z]{2}-[A-Z]{2}$/;
    const isLocaleInUrl = splitUrl[1] ? regex.test(splitUrl[1]) : false;
    const localeFromUrl = isLocaleInUrl ? splitUrl[1] : DEFAULT_LOCALE;

    updateLocationBasedCookies(localeFromUrl);
  }, [updateLocationBasedCookies]);

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      const splitUrl = url.split('/');

      const regex = /[a-z]{2}-[A-Z]{2}$/;
      const isLocaleInUrl = splitUrl[1] ? regex.test(splitUrl[1]) : false;
      const localeFromUrl = isLocaleInUrl ? splitUrl[1] : DEFAULT_LOCALE;
      updateLocationBasedCookies(localeFromUrl);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, updateLocationBasedCookies]);

  useEffect(() => {
    const handleRouteChangeStart = (): void => {
      NProgress.start();
    };

    const handleRouteChangeEnd = (): void => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
    };
  }, [router.events]);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { err } = rest as any;

  const setPrevPath = useBuildStore(getSetPrevPath);
  const setCurrentPath = useBuildStore(getSetCurrentPath);
  const currentPath = useBuildStore(getCurrentPath);

  useEffect(() => {
    if (router.asPath !== currentPath) {
      if (currentPath && !currentPath.startsWith(`/${ROUTE_BUILD}`)) {
        setPrevPath(currentPath);
      }
      setCurrentPath(router.asPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return (
    <AuthProvider session={pageProps.session}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={messages}
      >
        <DefaultSeo {...SEO_DEFAULTS} />
        <CommerceProvider
          locale={localeCookie}
          cartCookieValue={cartCookie}
          shopifyCartCookie={shopifyCartCookie}
        >
          <Layout pageProps={pageProps}>
            <Component {...pageProps} err={err} />
            {cookieControlId &&
              cookieControlSrc &&
              (vercelEnv === 'production' || vercelEnv === 'preview' ? (
                <CookieControl
                  scriptSrc={cookieControlSrc}
                  apiKey={cookieControlId}
                  heapId={heapId}
                  gtmId={gtmId}
                  gtmServerId={gtmServerId}
                  locale={locale}
                />
              ) : null)}
            <ExtendConfig />
            <InitializeAftermarket />
          </Layout>
        </CommerceProvider>
      </IntlProvider>
    </AuthProvider>
  );
};

export default MyApp;
