export const ZENDESK_CUSTOM_FIELD_ID_REGION = 22491970;
export const ZENDESK_CUSTOM_FIELD_ID_PRODUCT = 22642170;
export const ZENDESK_CUSTOM_FIELD_ID_CONTACT = 22478634;

export const ZENDESK_BRAND_ID_CAM = '114094740213';
export const ZENDESK_BRAND_ID_DEFAULT = '36634';

export const ZENDESK_ARTICLES_URL = 'https://support.nzxt.com/hc/en-us';
export const ZENDESK_TICKET_URL =
  'https://support.nzxt.com/hc/en-us/requests/new';
export const ZENDESK_CHAT_KEY = 'd0bd149e-0db2-435e-b7b3-4382195620c3';
export const ZENDESK_SCRIPT_SRC =
  'https://static.zdassets.com/ekr/snippet.js?key=';

const LAUNCHER = {
  label: {
    '*': 'Support',
    en: 'Support',
    de: 'Hilfe',
    fr: "Service d'assistance",
    it: 'Servizio clienti',
    nl: 'Support',
    es: 'Soporte',
  },
};

export const ZENDESK_WIDGET_CONFIG = {
  webWidget: {
    position: {
      horizontal: 'right',
    },
    color: { theme: '#313234' },
    zIndex: 40,
    offset: {
      vertical: '10px',
      mobile: {
        horizontal: '10px',
        vertical: '62px',
      },
    },
    launcher: LAUNCHER,
  },
};

const BLD_OFFSET = {
  vertical: '82px',
  mobile: {
    horizontal: '10px',
    vertical: '110px',
  },
};

export const ZENDESK_WIDGET_CONFIG_LG_BUILD = {
  webWidget: {
    position: {
      horizontal: 'left',
    },
    color: { theme: '#121214' },
    zIndex: 40,
    offset: BLD_OFFSET,
    launcher: LAUNCHER,
  },
};

export const ZENDESK_WIDGET_CONFIG_SM_BUILD = {
  webWidget: {
    position: {
      horizontal: 'right',
    },
    color: { theme: '#121214' },
    zIndex: 40,
    offset: BLD_OFFSET,
    launcher: LAUNCHER,
  },
};
