/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRouter } from 'next/router';
import { DEFAULT_COUNTRY_CODE, DEFAULT_LOCALE } from '@constants';
import parseLocale from '@utils/parse-locale';
import type { HookFetcher } from '../utils/types';
import type { SwrOptions } from '../utils/use-data';
import { getShopifyCartCookie } from '../api/utils/shopify-cart-cookie';
import useCommerceCart, { CartInput } from '../commerce/cart/use-cart';
import type { ShopifyCart } from '../api/cart';

const defaultOpts = {
  url: '/api/maxify/cart',
  method: 'GET',
};

export type { ShopifyCart };

export const fetcher: HookFetcher<ShopifyCart | null, CartInput> = (
  options,
  { cartId },
  fetch
) => (cartId ? fetch({ ...defaultOpts, ...options }) : null);

export function extendHook(
  customFetcher: typeof fetcher,
  swrOptions?: SwrOptions<ShopifyCart | null, CartInput>
) {
  const useCart = () => {
    const router = useRouter();
    const { locale } = router;
    const parsedLocale = parseLocale(DEFAULT_LOCALE, locale);
    const country = parsedLocale[1] || DEFAULT_COUNTRY_CODE;
    const response = useCommerceCart(
      defaultOpts,
      [['cartId', getShopifyCartCookie(country)]], // append cartId to swr cache key
      customFetcher,
      {
        revalidateOnFocus: false,
        ...swrOptions,
      }
    );

    // Uses a getter to only calculate the prop when required
    // response.data is also a getter and it's better to not trigger it early
    Object.defineProperty(response, 'isEmpty', {
      get() {
        return response.data?.lines?.edges?.length <= 0;
      },
      set: x => x,
    });

    Object.defineProperty(response, 'count', {
      get() {
        const noBuildItems =
          response.data?.lines?.edges?.filter(
            i => !i?.node?.attributes?.some(a => a.key === 'build_number')
          ) ?? [];

        const warrItems =
          response.data?.lines?.edges?.filter(
            i =>
              i?.node?.attributes?.some(a => a.key === 'build_number') &&
              i?.node?.merchandise?.title?.includes('Extended Warranty')
          ) ?? [];

        const items = [...noBuildItems, ...warrItems];

        const itemQtys = items.map(n => n?.node?.quantity ?? 0);

        const itemCount =
          itemQtys.length > 0
            ? itemQtys.reduce((prev, current) => prev + current, 0)
            : 0;

        const buildItems =
          response.data?.lines?.edges?.filter(i =>
            i?.node?.attributes?.some(a => a.key === 'build_number')
          ) ?? [];

        const buildNumbers = [
          ...new Set(
            buildItems.map(
              i =>
                i?.node?.attributes?.find(a => a.key === 'build_number')
                  .value ?? null
            )
          ),
        ];

        const buildQtys = {};

        buildNumbers.forEach(buildNo => {
          const bld = buildItems.find(b =>
            b?.node?.attributes?.find(
              a => a.key === 'build_number' && a.value === buildNo
            )
          );

          buildQtys[buildNo] = bld?.node?.quantity ?? 0;
        });

        const buildCount = Object.keys(buildQtys)
          .map(k => buildQtys[k])
          .reduce((prev, current) => prev + current, 0);

        return itemCount + buildCount;
      },
      set: x => x,
    });

    return response;
  };

  useCart.extend = extendHook;

  return useCart;
}

export default extendHook(fetcher);
