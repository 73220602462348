import waitForGlobal from '@utils/wait-for-global';

const onAccept = (): void => {
  waitForGlobal('zE', () => {
    window.zE('webWidget', 'updateSettings', {
      cookies: true,
    });
  });
};

export default onAccept;
