import waitForGlobal from '@utils/wait-for-global';

const onRevoke = (): void => {
  waitForGlobal('zE', () => {
    window.zE('webWidget', 'updateSettings', {
      cookies: false,
    });
  });
};

export default onRevoke;
