/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';
import type { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { MODAL_VIEW_LOGIN } from '@constants/ui';
import type { Variant } from '@framework/api/types';
import type {
  State,
  ModalContent,
  CartStockNotice,
  ProductDetails,
} from './types';

const initialModalState = {
  displayModal: false,
  modalView: MODAL_VIEW_LOGIN,
  modalAction: () => null,
  modalOnOpenAction: () => null,
  modalOnCloseAction: () => null,
  modalContent: {
    markdownCopy: null,
    markdownContent: null,
    internalLink: null,
  },
  selectedVariant: {
    color: [],
    images: [],
    price: null,
    stock: null,
    id: null,
    shopify_variants_id: null,
    isValid: false,
    isLowStock: false,
    isOutOfStock: false,
    collectionPageMainImage: null,
    isOnSale: false,
    discounted_price: null,
    isComingSoon: false,
    isNewItem: false,
  },
  productDetails: { product: null, selectedVariant: null },
  product: null,
  additionalProducts: null,
};

const initialNavState = {
  displayNav: false,
  displayBuildNav: false,
  displayCart: false,
  displayOverlay: false,
  displayDrawer: false,
  displaySearch: false,
  displayDesktopNav: false,
  displayAccountNav: false,
};

const initialBannerState = {
  displayPreviewBanner: false,
  displayMarketingBanner: true,
};

const initialAuthState = {
  auth: {
    isTermsAccepted: false,
  },
};

const initialSoundButtonState = {
  soundButtonId: '0',
};

const initialCartStockNoticeState = {
  cartStockNotices: [],
};

const initialShopifyCartCookieLoadingState = {
  isShopifyCartCookieLoading: false,
};

const uiStore = (set: SetState<State>): State => ({
  ...initialModalState,
  ...initialNavState,
  ...initialBannerState,
  ...initialAuthState,
  ...initialSoundButtonState,
  ...initialCartStockNoticeState,
  ...initialShopifyCartCookieLoadingState,
  // Modal Actions
  openModal: () =>
    set(state => {
      state.modalOnOpenAction();
      return {
        displayModal: true,
        displayNav: false,
        displayBuildNav: false,
        displayCart: false,
        displaySearch: false,
        displayDesktopNav: false,
      };
    }),
  closeModal: () =>
    set(state => {
      state.modalOnCloseAction();
      return { displayModal: false };
    }),
  setModalView: view => set(() => ({ modalView: view })),
  setModalAction: fn => set(() => ({ modalAction: fn })),
  setModalOnOpenAction: fn => set(() => ({ modalOnOpenAction: fn })),
  setModalOnCloseAction: fn => set(() => ({ modalOnCloseAction: fn })),
  setSelectedVariant: (selectedVariant: Variant) =>
    set(() => ({ selectedVariant })),
  setProductDetails: (productDetails: ProductDetails) =>
    set(() => ({ productDetails })),
  setModalContent: (modalContent: ModalContent) =>
    set(() => ({ modalContent })),
  setAdditionalProductContent: (
    additionalProducts: any[],
    showWarning: boolean,
    modalWarning: string
  ) => set(() => ({ additionalProducts, showWarning, modalWarning })),
  // Nav Actions
  toggleNav: () =>
    set(state => ({
      displayNav: !state.displayNav,
      displayBuildNav: !state.displayBuildNav,
      displayOverlay: !state.displayNav,
      displayCart: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),

  closeNav: () =>
    set(() => ({
      displayNav: false,
      displayBuildNav: false,
      displayOverlay: false,
      displayCart: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  // Nav Build Actions
  toggleBuildNav: () =>
    set(state => ({
      displayNav: !state.displayNav,
      displayBuildNav: !state.displayBuildNav,
      displayOverlay: false,
      displayCart: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  closeBuildNav: () =>
    set(() => ({
      displayNav: false,
      displayBuildNav: false,
      displayOverlay: false,
      displayCart: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  // Cart Actions
  openCart: () =>
    set(() => ({
      displayCart: true,
      displayOverlay: true,
      displayNav: false,
      displayBuildNav: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  closeCart: () => set(() => ({ displayCart: false, displayOverlay: false })),
  toggleCart: () =>
    set(state => ({
      displayCart: !state.displayCart,
      displayOverlay: !state.displayCart,
      displayNav: false,
      displayBuildNav: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  // Drawer actions
  openDrawer: () =>
    set(() => ({
      displayDrawer: true,
      displayOverlay: true,
      displayNav: false,
      displayBuildNav: false,
      displayCart: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  closeDrawer: () =>
    set(() => ({ displayDrawer: false, displayOverlay: false })),
  // Overlay actions
  clickOverlay: () =>
    set(() => ({
      displayNav: false,
      displayBuildNav: false,
      displayCart: false,
      displayOverlay: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: false,
    })),
  // Search actions
  openSearch: () =>
    set(() => ({
      displayCart: false,
      displayOverlay: true,
      displayNav: false,
      displayBuildNav: false,
      displayDrawer: false,
      displaySearch: true,
      displayDesktopNav: false,
    })),
  closeSearch: () =>
    set(() => ({ displaySearch: false, displayOverlay: false })),
  toggleSearch: () =>
    set(state => ({
      displayCart: false,
      displayOverlay: !state.displaySearch,
      displayNav: false,
      displayBuildNav: false,
      displayDrawer: false,
      displaySearch: !state.displaySearch,
      displayDesktopNav: false,
    })),
  // Desktop Nav Actions
  toggleDesktopNav: () =>
    set(state => ({
      displayNav: false,
      displayBuildNav: false,
      displayOverlay: !state.displayDesktopNav,
      displayCart: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: !state.displayDesktopNav,
    })),
  openDesktopNav: () =>
    set(() => ({
      displayDesktopNav: true,
      displayOverlay: true,
      displaySearch: false,
    })),
  closeDesktopNav: () =>
    set(() => ({
      displayDesktopNav: false,
      displayOverlay: false,
    })),
  // Desktop Account Nav Actions
  toggleAccountNav: () =>
    set(state => ({
      displayNav: false,
      displayBuildNav: false,
      displayCart: false,
      displayDrawer: false,
      displaySearch: false,
      displayDesktopNav: false,
      displayAccountNav: !state.displayAccountNav,
      displayOverlay: !state.displayAccountNav,
    })),
  openAccountNav: () =>
    set(() => ({
      displayAccountNav: true,
    })),
  closeAccountNav: () =>
    set(() => ({
      displayAccountNav: false,
      displayOverlay: false,
    })),
  // Banner actions
  showPreviewBanner: () => set(() => ({ displayPreviewBanner: true })),
  closePreviewBanner: () => set(() => ({ displayPreviewBanner: false })),
  openMarketingBanner: () => set(() => ({ displayMarketingBanner: true })),
  closeMarketingBanner: () => set(() => ({ displayMarketingBanner: false })),
  // Auth actions
  setIsTermsAccepted: (isTermsAccepted: boolean) =>
    set(state => ({ auth: { ...state.auth, isTermsAccepted } })),
  setLogout: () => set(() => ({ auth: {} })),
  // Button sound actions
  setSoundButtonId: (id: string | number) => set({ soundButtonId: id }),
  // Cart stock notice actions
  setCartStockNotice: (cartStockNotice: CartStockNotice) =>
    set(state => {
      const allCartStockNotices = [...state.cartStockNotices, cartStockNotice];

      const cartStockNotices = [
        ...new Map(allCartStockNotices.map(v => [v.buildLink, v])).values(),
      ];

      return {
        cartStockNotices,
      };
    }),
  removeCartStockNotice: (cartStockNotice: CartStockNotice) =>
    set(state => {
      const cartStockNotices = state.cartStockNotices.filter(
        n => n.buildLink !== cartStockNotice.buildLink
      );

      return {
        cartStockNotices,
      };
    }),
  clearCartStockNotices: () => set(() => ({ cartStockNotices: [] })),
  setIsShopifyCartCookieLoading: (isShopifyCartCookieLoading: boolean) =>
    set(() => ({ isShopifyCartCookieLoading })),
});

const useUiStore =
  process.env.NODE_ENV === 'development'
    ? create<State>(devtools(uiStore))
    : create<State>(uiStore);

export default useUiStore;
