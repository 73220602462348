import { CLIENT_ID, SCRIPT_SRC } from './constants';

const onAccept = (): void => {
  if (
    typeof window !== 'undefined' &&
    process.env.NEXT_PUBLIC_FEATURE_ENABLE_KLARNA === 'true'
  ) {
    const klarnaScript = document.getElementById('klarna-script');

    if (!klarnaScript) {
      const script = document.createElement('script');
      script.async = true;
      script.src = SCRIPT_SRC;
      script.id = 'klarna-script';
      script.setAttribute('data-client-id', CLIENT_ID);

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  }
};

export default onAccept;
