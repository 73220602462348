export const SCRIPT_ID = 'heap-script';

export const COOKIES = [
  '_mkto_trk',
  '_hp2_*',
  '_gcl_au',
  '_gid',
  '_ga',
  '_fbp',
  '_attribution_landing_page',
  '_gd_session',
  '_gd_visitor',
  '_an_uid',
  '_attribution_referrer',
  '_csrf',
  '_gd_svisitor',
  'heap',
];
