import { getSessionStorage } from '@utils/sessionStorage';
import type {
  State,
  BldOptions,
  BldPeripherals,
  BldHelpInfo,
  InfoModals,
} from './types';

export const getBldOptions = (state: State): BldOptions => state.bldOptions;

export const getBldPeripherals = (state: State): BldPeripherals =>
  state.bldPeripherals;

export const getBldEntryVariantId = (state: State): string | number =>
  state.bldEntryVariantId;

export const getBldMoreInfoVariantId = (state: State): string | number =>
  state.bldMoreInfoVariantId;

export const getBldHelpInfo = (state: State): BldHelpInfo => state.bldHelpInfo;

export const getSetBLDOptions = (
  state: State
): ((options: BldOptions) => void) => state.setBLDOptions;

export const getSetBldPeripherals = (
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((bldPeripherals: BldPeripherals | any) => void) => state.setBldPeripherals;

export const getSetBLDEntryVariantId = (
  state: State
): ((variantId: string | number) => void) => state.setBLDEntryVariantId;

export const getSetBLDMoreInfoVariantId = (
  state: State
): ((variantId: string | number) => void) => state.setBLDMoreInfoVariantId;

export const getSetBLDHelpInfo = (
  state: State
): ((BldHelpInfo: BldHelpInfo) => void) => state.setBLDHelpInfo;

export const getInfoModals = (state: State): InfoModals => state.infoModals;

export const getSetInfoModals = (
  state: State
): ((infoModals: InfoModals) => void) => state.setInfoModals;

export const getPrevPath = (): string => getSessionStorage('prevPath');

export const getSetPrevPath = (state: State): ((pathname: string) => void) =>
  state.setPrevPath;

export const getCurrentPath = (): string => getSessionStorage('currentPath');

export const getSetCurrentPath = (state: State): ((pathname: string) => void) =>
  state.setCurrentPath;
