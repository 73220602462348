import { setCookie } from 'nookies';
import { v4 as uuidv4 } from 'uuid';
import { HEAP_ID_COOKIE_NAME, SYSTEM_COOKIE_MAX_AGE } from '@constants';

const setId = (): string => {
  const id = uuidv4();

  setCookie(null, HEAP_ID_COOKIE_NAME, `${id}`, {
    maxAge: SYSTEM_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });

  return id;
};

export default setId;
