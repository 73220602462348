/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';
import useCartAddItem from '../commerce/cart/use-add-item';
import type { HookFetcher } from '../utils/types';
import { CommerceError } from '../utils/errors';
import type { ItemBody, AddItemBody } from '../api/cart';
import useCart, { ShopifyCart } from './use-cart';

const defaultOpts = {
  url: '/api/maxify/cart',
  method: 'POST',
};

export type AddItemInput = ItemBody | ItemBody[];

export const fetcher: HookFetcher<ShopifyCart, AddItemBody> = (
  options,
  { item },
  fetch
) => {
  if (
    !Array.isArray(item) &&
    item.quantity &&
    (!Number.isInteger(item.quantity) || item.quantity! < 1)
  ) {
    throw new CommerceError({
      message: 'The item quantity has to be a valid integer greater than 0',
    });
  }

  if (
    Array.isArray(item) &&
    item.some(
      i => i.quantity && (!Number.isInteger(i.quantity) || i.quantity! < 1)
    )
  ) {
    throw new CommerceError({
      message: 'The item quantity has to be a valid integer greater than 0',
    });
  }

  return fetch({
    ...defaultOpts,
    ...options,
    body: { item },
  });
};

export function extendHook(customFetcher: typeof fetcher) {
  const useAddItem = () => {
    const { mutate } = useCart();
    const fn = useCartAddItem(defaultOpts, customFetcher);

    return useCallback(
      async function addItem(input: AddItemInput) {
        const data = await fn({ item: input });
        await mutate(data, false);
        return data;
      },
      [fn, mutate]
    );
  };

  useAddItem.extend = extendHook;

  return useAddItem;
}

export default extendHook(fetcher);
