/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'Account',
  user_settings_change_password: 'Wijzig wachtwoord',
  user_settings_save: 'Opslaan',
  user_settings_cancel: 'Annuleren',
  user_profile_heading: 'Profiel',
  user_saved_builds_heading: 'Opgeslagen samenstellingen',
  user_saved_builds_bld_name: 'Build naam',
  user_saved_builds_bld_date: 'Aanmaakdatum',
  user_saved_builds_bld_item_part: 'Deel',
  user_saved_builds_delete_bld: 'Verwijder BLD',
  user_saved_builds_resume_bld: 'Hervat BLD',
  user_saved_builds_hero_title: 'Ontworpen om te pronken met je build',
  user_saved_builds_hero_text:
    "Het compacte verticale ontwerp behoudt ondersteuning voor de meeste full-size GPU's op de markt, zodat je jouw bureau kunt behouden zonder in te boeten aan grafische kracht.",
  user_saved_builds_pc_heading: 'PC Gebouwen',
  user_saved_builds_keyboard_heading: 'Toetsenbord Bouwt',
  user_saved_builds_view_pc: 'PC-bouwsels bekijken',
  user_saved_builds_view_keyboard: 'Toetsenbordbouwsels bekijken',
  user_saved_builds_no_pc: 'U heeft geen opgeslagen PC Builds',
  user_saved_builds_no_kb: 'U heeft geen opgeslagen Keyboard Builds',
  user_order_history_heading: 'Bestelgeschiedenis',
  user_order_history_date: 'Datum',
  user_order_history_order_number: 'Bestelnummer',
  user_order_history_status: 'Status',
  user_order_history_amount: 'Hoeveelheid',
  user_order_history_view: 'Bekijk bon',
  user_order_history_empty_view: 'Je hebt geen bestelgeschiedenis.',
  user_order_history_shipping: 'Verzendadres',
  user_order_history_billing: 'Factuur Adres',
  user_order_history_item: 'Artikel',
  user_order_history_order: 'Bestellen #',
  user_order_history_item_image: 'Item afbeelding',
  user_order_history_quantity: 'Hoeveelheid',
  user_support_heading: 'Support + Retourneringen',
  user_data_heading: 'Data verzoek',
  user_terms_heading: 'Voorwaarden',
  user_terms_empty: 'Je hebt nog geen ondertekende voorwaarden.',
  user_support_subject_min_length:
    'Het onderwerp moet langer zijn dan 3 tekens',
  user_support_message_placeholder:
    'Geef de redenen voor contact op. Vermeld bij retournering het bestelnummer',
  user_support_update_button: 'Update',
  user_active_tickets_heading: 'Actieve tickets',
  user_active_tickets_chat: 'Voeg een bericht toe',
  user_active_tickets_no_chat_history: 'Je hebt geen chatgeschiedenis',
  view_chat_button: 'Bekijk chat',
  close_chat_button: 'Sluit chat',
  field_required_message: 'dit veld is verplicht',
  label_accept: 'Aanvaarden',
  label_reason_for_contact: 'Reden voor contact',
  label_country: 'Land',
  label_subject: 'Onderwerpen',
  label_product: 'Product',
  label_message: 'Bericht',
  label_requestor_type: 'Type aanvrager',
  label_country_of_residence: 'Het land waar je woont',
  label_description: 'Omschrijving',
  placeholder_description: 'Beschrijving van jouw verzoek',
  label_submit: 'Verzenden',
  message_no_support_requests: 'Je hebt geen actieve ondersteuningsverzoeken',
  label_ticket_number: 'Ticket nummer',
  label_date_created: 'Datum gecreeërd',
  label_status: 'Status',
  label_department: 'Afdeling',
  subheading_active_ticket:
    'Als je hulp nodig hebt of een artikel wilt retourneren, neem dan contact op met onze support.',
  label_create_support_request: 'Maak een supportsverzoek',
  button_comment: 'Comment',
  label_for_myself: 'Voor mezelf',
  label_parent: 'Ouder',
  label_legal_rep: 'Wettelijke vertegenwoordiger',
  label_relative: 'Familielid',
  label_guardian: 'Voogd',
  label_accept_generic_terms:
    'Ik heb deze voorwaarden gelezen en accepteer deze',
  error_view_message: 'Oeps, er is iets misgegaan.',
  signout_view_message: 'Je bent uitgelogd.',
  user_order_history_close: 'Sluiten',
  birthday_error_message: 'Voer een geldige geboortedatum in',
  label_view_saved: 'Bekijk alle opgeslagen builds',
};

const formErrors = {
  invalid_email: 'Voer een geldig e-mailadres in',
  username_full_pattern_message:
    'De gebruikersnaam moet 6 tot 30 tekens lang zijn. U kunt letters, cijfers en punten gebruiken, maar mogen geen opeenvolgende punten beginnen, eindigen of bevatten.',
  password_pattern_message:
    'Wachtwoord moet minimaal zes tekens, één hoofdletter, één kleine letter en één cijfer bevatten',
  username_min_length_message:
    'Gebruik minimaal 6 tekens voor je gebruikersnaam',
  passwords_must_match: 'Wachtwoorden moeten overeenkomen',
  invalid_current_password:
    'Kan het wachtwoord niet bijwerken. De waarde die is opgegeven als het huidige wachtwoord is onjuist',
  must_accept_partner_program_terms:
    'U moet de voorwaarden van het partnerprogramma accepteren om een ​​NZXT-partner te worden',
  must_accept_tos_terms:
    'Je moet de Servicevoorwaarden accepteren om een NZXT-account aan te maken',
  must_accept_generic_terms: 'U moet akkoord gaan met deze voorwaarden',
};

const auth = {
  user_auth_agreement: "Ik accepteer NZXT's",
  user_auth_privacy_policy: 'privacybeleid',
  user_auth_and: 'en heb de',
  user_auth_terms: 'de algemene voorwaarden gelezen',
  error_invalid_request: 'Ongeldig verzoek.',
  error_invalid_token: 'Ongeldig token voor gebruikersverificatie',
  error_session_expired:
    'Jouw sessie is verlopen. Log opnieuw in om door te gaan.',
  user_forgot_required_email: 'E-mailadres is vereist',
  user_forgot_success_message:
    'Bedankt! Als er een account aan deze e-mail is gekoppeld, sturen we de instructies voor het opnieuw instellen van het wachtwoord onmiddellijk.',
  error_email_exists: 'De e-mail is al in gebruik',
  user_email_verification_heading: 'email verificatie',
  error_email_not_verified:
    'Het e-mailadres is nog niet geverifieerd. Om de verificatie-e-mail opnieuw te verzenden, klikt u op',
  error_email_already_verified:
    'Het e-mailadres is al geverifieerd. Ga verder met inloggen.',
  user_email_verification_success_message:
    'Uw e-mailadres is succesvol geverifieerd!. Ga verder met inloggen.',
  error_username_unavailable: 'De gebruikersnaam is niet beschikbaar',
  error_reset_password_same_as_old_password:
    'Dit wachtwoord is hetzelfde als voorheen. Selecteer een andere.',
  error_reset_password_link_expired:
    'De link om jouw wachtwoord opnieuw in te stellen is verlopen.',
  error_reset_password_link_invalid:
    'De link om jouw wachtwoord opnieuw in te stellen is ongeldig.',
  user_forgot_password_subheading:
    'Voer jouw e-mailadres in om instructies te ontvangen om jouw wachtwoord opnieuw in te stellen.',
  user_reset_password_heading: 'Stel je wachtwoord opnieuw in',
  user_reset_password_subheading: 'Voer een nieuw wachtwoord in.',
  user_reset_password: 'Wachtwoord opnieuw instellen',
  user_reset_success_message: 'Je hebt je wachtwoord succesvol gereset!',
  user_login_text: 'Registreren met Google',
  user_login_remember: 'Onthoud mij',
  user_login_forgot_password: 'Wachtwoord vergeten?',
  user_login_wrong_email_or_password: 'Verkeerd e-mailadres of wachtwoord',
  user_login_success_message: 'Je bent succesvol ingelogd!',
  error_too_many_requests:
    'Je hebt te veel verzoeken gedaan. Wacht een paar minuten of neem contact op met de support',
  error_user_not_found: 'Gebruiker is niet gevonden',
  user_sign_in_sign_up: 'Aanmelden / Registreren',
  user_sign_out: 'Afmelden',
  user_sign_in: 'Aanmelden',
  user_account_updates: 'NZXT-updates',
  user_account_no_updates: 'Geen recente updates, kom later terug.',
  support_center_link: 'Help & Support',
  error_google_account_exists: 'Je hebt al een account aangemaakt met Google.',
  error_local_account_exists: 'Het lijkt erop dat je al een account hebt.',
  user_sign_up: 'Registreren',
  user_sign_up_text: 'Registreren met Google',
  user_sign_up_opt_in:
    'Meld je aan om via e-mail updates van NZXT te ontvangen over producten, BLD en CAM',
  user_sign_up_check_email_message:
    'Controleer je e-mail en klik op de link om je account te activeren.',
  user_sign_up_success_verify_message:
    'Het account blijft inactief totdat het geverifieerd is. Kijk in je spammap als je niet binnen enkele minuten een e-mail ontvangt.',
  user_info_updated_success_message: 'Jouw gegevens zijn succesvol bijgewerkt!',
  user_info_newsletter_confirmation_message:
    'Controleer je e-mail voor de bevestiging.',
  user_email_sign_in: 'Of ga door met',
  user_no_account: 'Geen lid?',
  user_create_account: 'Account aanmaken',
  user_email_sign_up_text: 'Of meld je aan met',
  user_have_account: 'Heb je al een account?',
  open_login_view: 'Open inlogscherm',
  error_newsletter_subscription:
    'Kan momenteel niet in- / uitschrijven voor de nieuwsbrief. Probeer het later nog eens.',
  user_login_too_many_requests:
    'Je hebt te veel aanvragen ingediend. Wacht enkele minuten of neem contact op met de support',
  label_email_address: 'E-mailadres',
  label_password: 'Wachtwoord',
  label_current_password: 'huidig ​​wachtwoord',
  label_confirm_password: 'bevestig wachtwoord',
  label_new_password: 'nieuw paswoord',
  label_confirm_new_password: 'Bevestig nieuw wachtwoord',
  label_username: 'gebruikersnaam',
  label_first_name: 'Voornaam',
  label_last_name: 'Achternaam',
  label_preferred_pronouns: 'Geprefereerde voornaamwoorden',
  label_search_for_help_articles: 'Help-artikelen zoeken',
  label_birthday: 'Verjaardag',
  label_location: 'Plaats',
  app_access: 'wil toegang tot je NZXT-account om toegang te krijgen tot:',
  app_access_has_scopes: 'wil toegang tot je NZXT account',
  scope_profile: 'je profiel, naam en foto',
  scope_email: 'je e-mailadres',
  scope_address: 'Uw fysieke adres',
  scope_phone: 'Jouw telefoon nummer',
  grant_offline_access: 'Offline toegang hebben',
  grant_offline_access_previous: 'die je eerder hebt verleend',
};

const build = {
  custom_bld_modal_heading: 'Bouw jouw PC',
  custom_bld_modal_subheading: 'Selecteer jouw chipset en budget',
  custom_bld_unavailable: 'Selecteer een item',
  custom_bld_issue: 'De volgende items moeten worden opgelost: ',
  custom_bld_title: 'Aangepaste BLD',
  custom_bld_lets_get_started: 'Aan de slag',
  custom_bld_destrutive_action: 'Waarschuwing: Reset samenstelling',
  custom_bld_confirm_reset:
    'Je samenstelling wordt gereset met de volgende chipset:',
  custom_bld_keyboard_confirm_reset: `Uw bouw zal worden gereset met deze verandering`,
  custom_bld_choose_chipset: 'Kies je chipset',
  custom_bld_chipset: 'Chipset',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Ontdekken',
  custom_bld_select: 'Selecteren',
  review_bld_button: 'Bekijk BLD',
  custom_bld_summary: 'Overzicht',
  custom_bld_budget: 'Budget',
  custom_bld_specs: 'Specificaties',
  custom_bld_case: 'Behuizing',
  custom_bld_cases: 'Behuizingen',
  custom_bld_caseCooling: 'Koeling',
  custom_bld_fans: 'Ventilatoren',
  custom_bld_cpu: 'CPU',
  custom_bld_gpu: 'GPU',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'HDD',
  custom_bld_specials: 'Specials',
  custom_bld_colors: 'Kleuren',
  custom_bld_color: 'Kleur',
  custom_bld_black: 'Zwart',
  custom_bld_red: 'Rood',
  custom_bld_white: 'Wit',
  custom_bld_grey: 'Grijs',
  custom_bld_blue: 'Blauw',
  custom_bld_purple: 'Paars',
  custom_bld_yellow: 'Geel',
  custom_bld_audio: 'Audio',
  custom_bld_extras: "Extra's",
  custom_bld_core: 'Core',
  custom_bld_motherboard: 'Moederbord',
  custom_bld_motherboards: 'Moederborden',
  custom_bld_powerSupplies: 'Voedingen',
  custom_bld_powerSupply: 'Voeding',
  custom_bld_services: 'Services',
  custom_bld_software: 'Software',
  custom_bld_monitors: 'Beeldschermen',
  custom_bld_peripherals: 'Randapparatuur',
  custom_bld_lighting: 'Verlichting',
  custom_bld_headset: 'Headset',
  custom_bld_keyboard: 'Toetsenbord',
  custom_bld_all: 'All',
  custom_bld_mouse: 'Muis',
  custom_bld_storage: 'Opslag',
  custom_bld_streaming_ready: 'Stream Ready',
  custom_bld_rgb_lighting: 'RGB-verlichting',
  custom_bld_vr_ready: 'VR Ready',
  custom_bld_wifi: 'Wifi',
  custom_bld_from: 'Van',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    "Je build is succesvol opgeslagen! Je kunt het bekijken in 'Opgeslagen builds' in jouw account.",
  custom_bld_save_modal_message: 'Wil je deze build opslaan voor later?',
  custom_bld_confirm_save_modal_message:
    'Je verliest niet-opgeslagen wijzigingen door deze pagina te verlaten.',
  custom_bld_share_build_message:
    'Je build is succesvol opgeslagen en gekopieerd!',
  help_me_decide: 'Help mij kiezen',
  save_build: 'Samenstelling opslaan',
  start_over: 'Opnieuw beginnen',
  summary: 'Samenvatting',
  build_summary: 'Build Samenvatting',
  load_build: 'Samenstelling laden',
  est_shipping: 'Geschatte datum van verzending',
  add_to_build: 'Toevoegen aan samenstelling',
  message_best_seller: 'Bestseller',
  message_invalid: 'Tijdelijk niet verkrijgbaar',
  message_out_of_stock: 'Niet op voorraad',
  message_low_stock: 'Beperkte voorraad beschikbaar',
  message_new_item: 'Nieuw',
  message_coming_soon: 'Binnenkort beschikbaar',
  message_is_locked: 'Niet Compatibel',
  load: 'Laden',
  save: 'Opslaan',
  restart: 'Herstarten',
  share: 'Delen',
  my_build_label: 'Mijn build',
  button_label_select: 'Selecteren',
  button_label_selected: 'Geselecteerd',
  button_label_add: 'Toevoegen',
  button_label_added: 'Toegevoegd',
  button_label_update: 'Update',
  button_label_remove: 'Verwijderen',
  button_label_info: 'Info',
  button_label_more_info: 'Meer info',
  button_label_options_info: 'Opties & info',
  button_label_continue_to: 'Doorgaan met bouwen',
  button_label_edit: 'Bewerken',
  build_fps_label:
    'FPS wordt gemeten bij een resolutie van 2560 x 1440 met de hoogste grafische settings van de game',
  build_includes: 'Omvat',
  custom_bld_boost: 'Boost je Build',
  custom_bld_fps_unavailable: 'In afwachting',
  custom_bld_amd_help:
    "Intel gebaseerde pc's zijn de afgelopen jaren de norm geweest maar AMD-pc's zijn tegenwoordig ook geweldig om mee te gamen. We helpen hebben systemen voor beide processoren en kiezen altijd voor moederborden en koelers die goet samenwerken. Zodat jij je geen zorgen hoeft te maken. We raden wel aan om een balans te zoeken met de FPS-prestaties die je zoekt en het budget wat je uit wilt geven.",
  custom_bld_to_see_this: 'Om deze selectie te zien, moet je',
  custom_bld_change_chipset: 'jouw selectie wijzigen.',
  performance: 'Prestaties',
  message_error: 'Oeps, er is iets misgegaan',
  custom_bld_prebuild_upsell:
    'Of kies snel en gemakkelijk één van onze prebuilds',
  no_item_selected: 'Geen Item Geselecteerd',
  build_change_label: 'Build Gewijzigd',
  invalid_action_label: 'Voorkomen',
  success_label: 'Succes',
  build_info_message: 'Info',
  reset_label: 'Build Reset',
  complete_build_label: 'Voltooi je Build',
  back_to_build_label: 'Terug Naar Je Build',
  complete_build_sub_label: "Extra's en Randapparatuur",
  back_to_build_sub_label: 'Kerncomponenten van jouw build',
  capacity_label: 'Capaciteit',
  option_label: 'Optie',
  options_label: 'Opties',
  max_speed: 'Maximale snelheid',
  modular_label: 'Modulair',
  midtower_label: 'Miditower',
  streaming_label: 'Streaming',
  save_and_exit: 'Opslaan en afsluiten',
  exit: 'Exit',
  view_cart_label: 'Winkelmandje',
  cart_subtotal_label: 'Winkelwagen Subtotaal',
  start_build_label: 'Start bouwen',
  new_build_label: 'Start met Bouwen',
  continue_saved_build_label: 'Ga verder met je opgeslagen bouw',
  continue_progress_build_label: 'Ga verder met jouw vorige build',
  continue_cart_build_label: 'Controleer de build in uw winkelwagen',
  core_label: 'Basis',
  extras_label: `Uitbreidingen`,
  summary_label: 'Samenvatting',
  share_build_pc: 'Op maat gemaakte PC',
  share_build_kb: 'Op maat gemaakt toetsenbord',
  build_summary_label: 'Overzicht van je toetsenbord-build.',
  pc_hub_card_heading: `Aangepaste Gaming PC's`,
  pc_hub_card_subheading:
    'Bouw jouw droom-PC rond jouw budget en geoptimaliseerd voor de games waar jij van houdt.',
  kb_hub_card_heading: 'Aangepaste toetsenborden',
  kb_hub_card_subheading:
    'Stel een toetsenbord en muis samen die bij jouw ruimte en gamestijl passen.',
};

const keyboard = {
  keyboard_label: 'Toetsenbord',
  keyboard_size: 'Toetsenbordgrootte',
  keyboard_base: 'Toetsenbordbasis',
  keyboard_primay_base_color: 'Basis Kleur',
  keyboard_layout: 'Toetsenbordindeling',
  keyboard_summary: 'Toetsenbordoverzicht',
  accent_keycaps: 'Accent Keycaps',
  cabletype: 'Accent Cable',
  additional_cable_color: 'Kabel Kleur',
  cable: 'Cable',
  additional_cable: 'Extra kabe',
  primary_keycaps: 'Basis Keycaps',
  switches: 'Switches',
  size: 'Grootte',
  layout: 'Layout',
  language: 'Taal',
  mouse_pad_label: 'Muismat',
  desk_mat_label: 'Mousepad',
  tools_label: 'Hulpmiddelen',
  mouse_label: 'Mouse',
  wrist_rest_label: 'Polssteun',
  cable_description:
    'Optionele gekleurde kabel. Een standaard zwarte kabel van 2 meter wordt meegeleverd.',
  cable_color_description: 'De kleur van de premiumkabel.',
  language_description: 'Bepaalt de positie van de Q, W, Z en A toetsen. ',
  layout_description: 'Bepaalt de grootte en positie van de toetsen.',
  keycap_accent_description:
    'Optionele kleur voor Escape, Enter, Pijl, en Numpad Enter toetsen.',
  primary_keycap_description: 'PBT dye-sublimated, Cherry profiel.',
  switch_description: 'Bepaalt de type-ervaring van elke toetsaanslag.',
  keyboard_base_color_description: 'De hoofdkleur van de toetsenbordbasis.',
  keyboard_base_size_description:
    'Bepaalt het aantal toetsen en de afmeting van het toetsenbord.',
  mouse_description: 'Lichtgewicht muis voor links- en rechtshandig gebruik.',
  summary_description: 'Overzicht van je toetsenbord-build',
  premium_label: 'Premium',
  base_label: 'Basis',
  keyboard_warning:
    'Selecteer de volgende items om verder te gaan met uw bouw:',
  switch_reset_warning:
    'Uw schakelaars zijn gereset. Gelieve nieuwe schakelaars te kiezen.',
  keycap_reset_warning:
    'Uw keycaps zijn gereset. Kies alstublieft nieuwe keycaps.',
  base_type: 'Build type',
  build_type_description: `Selecteer het type toetsenbord dat u wilt configureren`,
  full_custom_label: 'Volledig custom',
  barebones_disclaimer: 'Verander je Build type om dit item te selecteren',
  none_label: 'Geen',
};

const camCta = {
  cam_description:
    'Beheer prestaties, temperaturen en apparaten met één applicatie.',
  cam_windows_10: 'WINDOWS 10 64BIT',
};

const helpCta = {
  help_description:
    'Krijg uitgebreide begeleiding en support voor al je NZXT-producten en hulp bij garanties, retourneringen en technische zaken.',
};

const emailSignUp = {
  email_stay_in_touch: 'Blijf op de hoogte',
  email_sub_copy:
    'Blijf op de hoogte van de nieuwste releases, aanbiedingen en nieuws van NZXT.',
  email_legal: "Door te registreren, ga je akkoord met NZXT, CAM en BLD's",
  email_notify: 'Geef mij een seintje',
  email_notify_stock: 'Ontvang een melding wanneer dit item beschikbaar is.',
  estimated_performance_label: 'Geschatte FPS',
  see_estimated_performance_label: 'Zie Geschatte FPS',
};

const generic = {
  explore: 'Ontdek',
  cam_title: 'NZXT CAM',
  free_download: 'Gratis download',
  details: 'Details',
  help_amp_support: 'Help & support',
  help_and_support: 'Help en support',
  read_more: 'Meer info',
  privacy_policy: 'Privacybeleid',
  terms_conditions: 'Algemene voorwaarden',
  and: 'en',
  or: 'of',
  send: 'sturen',
  return_to: 'terug keren naar',
  previous: 'Vorige',
  next: 'Volgende',
  home: 'Start',
  keep_browsing: 'Verder browsen',
  create_build: 'Samenstelling maken',
  learn_more: 'Meer informatie',
  add_to_cart: 'Toevoegen aan winkelwagen',
  share_build: 'Samenstelling delen',
  view: 'Bekijken',
  close: 'Sluiten',
  price: 'Prijs',
  allow: 'Toestaan',
  cancel: 'Annuleren',
  here: 'hier',
  ok: 'Ok',
  button_start_bld: 'Start BLD',
  message_on_sale: 'In de aanbieding',
  button_buy: 'Kopen',
  button_start_build: 'Samenstelling starten',
  view_build: 'Samenstelling bekijken',
  button_back: 'Terug',
  performance: 'Prestatie',
  build: 'Bouwen',
  error_generic: 'Er is iets misgegaan. Probeer het later opnieuw.',
  label_support: 'Support',
  contact_support: 'Contact opnemen',
  browse_articles: 'Blader Door Artikelen',
  search_support_articles: 'Zoek naar helpartikelen',
  message_error: 'Uh-oh, het ziet eruit als er iets mis is',
  estimated_performance_label: 'Geschatte FPS',
  see_estimated_performance_label: 'Zie Geschatte FPS',
  estimated_fps: 'Estimated Frames per second (FPS)',
  title_related_items: 'Dit vind je misschien ook leuk',
  qty: 'QTY',
  category_from: 'Van',
  reviews_label: 'Beoordelingen',
  message_best_seller: 'Bestseller',
  message_invalid: 'Tijdelijk niet verkrijgbaar',
  message_out_of_stock: 'Niet op voorraad',
  message_low_stock: 'Beperkte voorraad beschikbaar',
  message_new_item: 'Nieuw',
  message_coming_soon: 'Binnenkort beschikbaar',
  message_featured_items: 'Aanbevolen producten in afbeelding',
  message_unavailable: 'Niet beschikbaar',
  message_colors: 'Kleuren',
  message_color: 'Kleur',
  label_return_policy: 'TERUGSTUURBELEID',
  label_warranty_policy: 'Garantie beleid',
  label_shop: 'Koop nu',
  label_days: 'Dagen',
  label_hours: 'Uren',
  label_minutes: 'Minuten',
  label_seconds: 'Seconden',
  label_promotion: 'Zie Promotie',
  label_promotions: 'Promoties',
  monthly_payments: 'Maandelijkse betalingen beschikbaar.',
  message_promotion: 'Promotie',
  discount_label: 'korting',
  off_label: 'Inactief',
  on_label: 'Aan',
  choose_label: 'Kies',
  move_to_previous_label: 'Ga naar vorige dia',
  move_to_next_label: 'Ga naar de volgende dia',
  active_slide_label: 'Actieve dia',
  move_to_inactive_slide: 'Ga naar inactieve dia',
  color_options: 'Kleur Opties',
  of: 'van',
  product_images: 'Product afbeeldingen',
  for: 'voor',
  carousel: 'Carrousel',
  go_to_image: 'Ga naar afbeelding',
  button_label_add: 'Toevoegen',
  button_label_remove: 'Verwijderen',
  increase_price_label: 'Prijsverhoging',
  decrease_price_label: 'Prijsverlaging',
  select_video_label: 'Video kiezen',
  play_label: 'Speel',
  pause_label: 'Pauzeer',
  buttons: 'Knoppen',
  open_modal_label: 'Open afbeelding zoom modal',
  close_modal_label: 'Sluit afbeelding Zoom Modal',
  image_zoom_modal: 'Afbeelding zoom modal',
  vr_ready_label: 'VR Ready',
  vr_not_ready_label: 'Niet VR Ready',
  streaming_ready_label: 'Stream Ready',
  streaming_not_ready_label: 'Niet Stream Ready',
  has_wifi_label: 'Heeft WiFi',
  no_wifi_label: 'Geen WiFi',
  has_bluetooth_label: 'Heeft Bluetooth',
  no_bluetooth_label: 'Geen Bluetooth',
  has_usbc_label: 'Heeft USB-C',
  no_usbc_label: 'Geen USB-C',
  rgb_included_label: 'Inclusief RGB-verlichting',
  rgb_not_included_label: 'RGB-verlichting niet inbegrepen',
  products_label: 'Producten',
  upsell_modal_label: 'Vaak samen gekocht',
  no_thanks_label: 'Nee bedankt',
  label_deal_ends: 'Deal eindigt in',
  label_show_less: 'Laat minder zien',
  label_show_more: 'Laat meer zien',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Diensten',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Moederbord',
  label_storage: 'Opslag',
  label_cooling: 'Koeling',
  label_psu: 'PSU',
  label_warranty: 'Garantie',
  label_accessories: 'Accessoires',
  label_fps: 'FPS',
  label_feature: 'Functies',
};

const cart = {
  cart_heading: 'Je winkelwagen',
  cart_heading_empty: 'Je winkelwagen is leeg',
  cart_subheading_empty: 'Ontdek je toekomstige lievelingsproducten',
  cart_free_shipping_cta: 'Nog maar €25 en je betaalt geen verzendkosten!',
  cart_free_shipping_exclusion: "(Exclusief aangepaste en voorgebouwde pc's)",
  cart_free_shipping_promo_cta: `Gratis verzending op alle bestellingen`,
  cart_heading_error: 'Er is iets misgegaan. Probeer het nog een keer.',
  cart_heading_success: 'Bedankt!',
  cart_label_subtotal: 'Subtotaal',
  cart_label_taxes: 'btw',
  cart_value_taxes: 'Wordt in de volgende stap berekend',
  cart_label_shipping: 'Verzending',
  cart_value_shipping_free: 'GRATIS',
  cart_value_disclaimer:
    '*Nieuwe en refurbished producten kunnen op verschillende momenten worden verwerkt en verzonden.',
  cart_label_total: 'Totaal',
  cart_button_checkout: 'Naar de kassa',
  cart_terms_message: 'Ik accepteer de',
  cart_terms_link_text: 'algemene servicevoorwaarden',
  cart_value_taxes_non_us: 'Inbegrepen',
  cart_value_shipping_calculated: 'Wordt in de volgende stap berekend',
  cart_consent_i_have_read: 'Ik heb het',
  cart_consent_privacy_policy: 'privacybeleid gelezen',
  cart_consent_privacy_policy_after: ' ',
  cart_au_terms_message: 'Ik accepteer de',
  cart_au_terms_link: 'Servicevoorwaarden',
  cart_au_consent_message:
    'Ik stem ermee in dat mijn persoonlijke gegevens worden verzameld en gebruikt in overeenstemming met het',
  cart_au_consent_link: 'Privacybeleid',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559) levert de goederen en diensten die jij hebt besteld op grond van haar',
  cart_au_terms_text_terms_link: 'Voorwaarden',
  cart_au_terms_text_2:
    'en verzamelt, gebruikt en onthult de persoonlijke informatie die het van jou verzamelt in overeenstemming met zijn',
  cart_au_terms_text_privacy_link: 'Privacybeleid',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'Diensten',
  cart_label_software: 'Software',
  cart_label_case: 'Behuizing',
  cart_label_motherboard: 'Moederbord',
  cart_label_cpu: 'CPU',
  cart_label_gpu: 'GPU',
  cart_label_power_supply: 'Voeding',
  cart_label_cpu_cooler: 'Koeling',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'Ventilatoren',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'Beeldschermen',
  cart_label_peripherals: 'Randapparatuur',
  cart_label_extras: `Extra's`,
  cart_label_keycaps: 'Toetsen',
  cart_label_keycap_accents: 'Toetsaccenten',
  cart_label_switches: 'Schakelaars',
  cart_label_keyboard_base: 'Toetsenbordbasis',
  cart_label_keycap_accent: 'Accenttoetsen',
  cart_label_accent_cable: 'Accentkabel',
  cart_label_mouse: 'Muis',
  cart_message_item_fallback: 'Dit item',
  cart_message_build_item_oos_before: 'Een item in uw',
  cart_message_build_item_oos_after:
    'is uitverkocht. Klik hier om uw gebouw bij te werken en toe te voegen aan uw winkelwagen.',
  cart_message_oos:
    'is momenteel niet op voorraad. Verwijder dit item om verder te gaan naar de kassa.',
};

const category = {
  explore_refurbished: 'Samenstellingen ontdekken',
  explore_bulk: 'Bulk ontdekken',
  bulk: 'Bulk',
  refurbished: 'Refurbished',
  refurbished_cta:
    'We hebben Builds in bulk geleverd aan gamingbedrijven, universiteiten, e-sport-organisaties en meer.',
  bulk_cta:
    'We hebben Builds in bulk geleverd aan gamingbedrijven, universiteiten, e-sport-organisaties en meer.',
};

const geoipBanner = {
  currently_browsing: 'Op dit moment aan het bekijken',
  banner_region_text:
    'Ga naar jouw regio om te shoppen of om content voor jouw regio te bekijken.',
  banner_button: 'Schakel naar',
  banner_country_text_shop: 'Winkel',
  banner_counrty_text:
    'of selecteer een ander land dat specifiek is voor uw locatie.',
  country_us: 'Verenigde Staten',
  country_canada: 'Canada',
  country_belgium: 'België',
  country_germany: 'Duitsland',
  country_luxembourg: 'Luxemburg',
  country_netherlands: 'Nederland',
  country_austria: 'Oostenrijk',
  country_france: 'Frankrijk',
  country_italy: 'Italië',
  country_spain: 'Spanje',
  country_uk: 'Verenigd Koninkrijk',
  country_australia: 'Australië',
  country_nz: 'Nieuw Zeeland',
  country_japan: 'Japan',
  region_na: 'Noord-Amerika',
  region_europe: 'Europa',
  region_oceania: 'Oceanië',
};

const ui = {
  loading: 'Laden',
};

const footer = {
  label_language: 'Taal',
  label_country: 'Land',
  manage_cookie_preferences: 'Cookie-instellingen beheren',
  all_rights_reserved: 'Alle rechten voorbehouden',
};

const search = {
  label_suggested_queries: 'Voorgestelde zoekopdrachten',
  label_suggested_results: 'Voorgestelde resultaten',
  label_serp_heading: 'Zoek in resultaten op',
  label_serp_not_found: 'Geen resultaten gevonden voor',
  label_serp_total: 'totale resultaten',
  label_serp_suggested: 'Probeer te zoeken naar',
};

const about = {
  label_customer_support_hours: 'Openingstijden klantenservice',
  label_headquarters: 'Hoofdkantoor',
};

const product = {
  overview: 'Overzicht',
  tech_specs: 'Technische specificaties',
  downloads: 'Downloads',
  message_invalid: 'Tijdelijk niet verkrijgbaar',
  message_out_of_stock: 'Niet op voorraad',
  message_low_stock: 'Beperkte voorraad beschikbaar',
  message_new_item: 'Nieuw',
  message_coming_soon: 'Binnenkort Beschikbaar',
  message_unavailable: 'Niet beschikbaar',
  title_highlights: 'Belangrijkste kenmerken',
  title_shipping: 'Verzendkosten',
  title_in_the_box: 'In de doos',
  button_buy_now: 'Nu kopen',
  message_colors: 'Kleuren',
  message_color: 'Kleur',
  message_size: 'Grootte',
  message_series: 'Serie',
  overview_anchor: 'Overzicht',
  tech_specs_anchor: 'Technische specificaties',
  downloads_anchor: 'Downloads',
  related_anchor: 'Gerelateerd',
  customize_build_header: 'Aanpassen',
  customize_build_subheader: 'Begin een Build met dit item',
  customize_kb_build_subheader: 'Bouw een aangepast toetsenbord',
  buy_product_header: 'Doe het zelf',
  buy_product_subheader: 'Droom het. Build het. Doe het.',
  buy_label: 'Kopen',
  retailers_subheader: 'Winkel bij onze partners.',
  link_all_retailers: 'Bekijk alle winkels',
  series_label: 'Serie',
  label_header_bundle: 'Samen kopen',
  label_subheader_bundle:
    'Breng je build naar een hoger niveau met door experts aanbevolen producten',
  help_button_label: 'Chat nu',
  help_button_heading: 'Heb je vragen?',
  help_button_subheading: 'Onze experts staan ​​klaar om te helpen.',
  configure_label: 'Configureren',
  documentation_label: 'Documentatie',
  drivers_label: 'Drivers',
  miscellaneous_label: 'Diversen',
  resources_label: 'Bronnen',
  everything_you_need_label: 'Everything you need',
  good_rating_label: 'Goed',
  better_rating_label: 'Beter',
  best_rating_label: 'Best',
  key_specs_label: 'Belangrijkste specificaties',
};

const collection = {
  message_invalid: 'Tijdelijk niet verkrijgbaar',
  message_out_of_stock: 'Niet op voorraad',
  message_low_stock: 'Beperkte voorraad beschikbaar',
  message_new_item: 'Nieuw',
  message_coming_soon: 'Binnenkort beschikbaar',
  message_unavailable: 'Niet beschikbaar',
  button_buy_now: 'Nu kopen',
  message_colors: 'Kleuren',
  learn_more: 'Meer informatie',
  help_button_label: 'Chat nu',
  help_button_heading: 'Heb je vragen?',
  help_button_subheading: 'Onze experts staan ​​klaar om te helpen.',
};

const news = {
  label_all_news: 'Al het nieuws',
};

const retailers = {
  label_regions: "Regio's",
  label_locations: 'Locaties',
  label_reseller_type: 'Type',
  option_label_all_regions: "Alle regio's",
  option_label_all_locations: 'Alle locaties',
  option_label_all_reseller_type: 'Alle opties',
  message_empty_state:
    'Geen enkele detailhandelaar voldoet aan de geselecteerde criteria.',
  hero_text:
    "NZXT-producten kunnen in alle regio's via onze partners worden gekocht",
};

const searchResults = {
  message_invalid: 'Tijdelijk niet verkrijgbaar',
  message_out_of_stock: 'Niet op voorraad',
  message_low_stock: 'Beperkte voorraad beschikbaar',
  message_new_item: 'Nieuw',
  message_coming_soon: 'Binnenkort beschikbaar',
  message_unavailable: 'Niet beschikbaar',
};

const orderTracking = {
  title_order_status: 'Bestelstatus',
  label_cancelled_on: 'Geannuleerd op',
  label_shipping_date: 'verwachtte Verzenddatum',
  label_case: 'Geval',
  label_software: 'Software',
  label_services: 'Diensten',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Moederbord',
  label_storage: 'Opslag',
  label_cooling: 'Koeling',
  label_psu: 'PSU',
};

const orderTrackingRoot = {
  title_order_tracking_root: 'Voer jouw bestellings-ID in',
  label_build_id: 'Order ID',
  button_track: 'Order volgen',
  message_not_found: 'niet gevonden, probeer het opnieuw',
};

const refurb = {
  label_all_products: 'Alle producten',
  label_relevance: 'Relevantie',
  label_price_low_high: 'Prijs laag naar hoog',
  label_price_high_low: 'Prijs van hoog naar laag',
  label_name_a_z: 'Noem A-Z',
  label_name_z_a: 'Noem Z-A',
  label_case: 'Geval',
  label_software: 'Software',
  label_services: 'Diensten',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Moederbord',
  label_storage: 'Opslag',
  label_cooling: 'Koeling',
  label_psu: 'PSU',
  label_other: 'Ander',
  label_accessories: 'Accessoires',
  label_fan: 'Ventilatoren',
  label_any_price: 'Iedere Prijs',
  label_no_products:
    'Op dit moment zijn er geen refurbished compenenten beschikbaar.',
  label_no_builds: `Op dit moment zijn er geen refurbished samenstellingen beschikbaar.`,
};

const orderStatus = {
  order_status_title: 'Order Status',
  step_label: 'Stap',
  confirmed_title: 'Bestelling geplaatst',
  confirmed_sub: 'Je bestelling is ontvangen.',
  processed_title: 'Bestelling bevestigd',
  processed_sub: 'Je bestelling is ontvangen en de betaling is verwerkt.',
  in_progress_title: 'In uitvoering',
  in_progress_sub:
    'Het orderpicken en monteren van uw bestelling is begonnen. Uw bestelling is nu vergrendeld en wijzigingen/annuleringen kunnen niet worden gegarandeerd.',
  shipped_title: 'verzonden',
  shipped_sub: 'Uw bestelling is onderweg!',
  cancelled_title: 'Geannuleerd',
  cancelled_sub: 'Uw bestelling is succesvol geannuleerd.',
  enh_ordered_sub: 'Uw bestelling is ontvangen en staat in de wachtrij.',
  enh_picking_title_bld: 'Bouw voorbereiden',
  enh_picking_title: 'Uw bestelling verwerken',
  enh_picking_sub_bld:
    'Het picken en monteren van uw bestelling is begonnen. Wijzigingen in uw bestelling kunnen niet worden gegarandeerd.',
  enh_picking_sub:
    'We zijn je bestelling aan het voorbereiden. Wijzigingen in uw bestelling kunnen niet worden gegarandeerd.',
  enh_build_title: 'Gebouw',
  enh_build_sub: 'Uw bestelling wordt gebouwd en getest.',
  enh_packing_title: 'Het voorbereiden voor verzending',
  enh_packing_sub_bld:
    'Uw bestelling is gebouwd en getest. Het wordt ingepakt voor bezorging.',
  enh_packing_sub: 'Uw bestelling is verwerkt en wordt ingepakt voor levering.',
  enh_shipping_title: 'Verzending',
  enh_shipping_sub:
    'Uw bestelling is verzonden naar de vervoerder voor levering. Uw trackingnummer wordt binnen 1 werkdag bijgewerkt en naar u gemaild.',
};

const universal = {
  ...cart,
  ...userAccount,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const nl = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about': {
    ...about,
    ...universal,
  },
  '/about/[slug]': {
    ...about,
    ...universal,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  universal,
};
