// default build options
export const CONFIGURATOR_DEFAULT_CHIPSET = 'intel';
export const CONFIGURATOR_DEFAULT_PRODUCT = 260;
export const CONFIGURATOR_DEFAULT_PRICE = '2000';

export const MAX_ORDER_THRESHOLD_RAM = 2;
export const MAX_ORDER_THRESHOLD_STORAGE = 3;
export const MAX_ORDER_THRESHOLD_COOLING = 4;
export const MAX_ORDER_THRESHOLD_MONITORS = 3;

export const CONFIGURATOR_CATEGORY_RAM = 'RAM';
export const CONFIGURATOR_CATEGORY_STORAGE = 'Storage';
export const CONFIGURATOR_CATEGORY_COOLING = 'Cooling';
export const CONFIGURATOR_CATEGORY_EXTRAS = 'Extras';
export const CONFIGURATOR_CATEGORY_LIGHTING = 'Lighting';
export const CONFIGURATOR_CATEGORY_MONITORS = 'Monitors';
export const CONFIGURATOR_CATEGORY_SERVICES = 'Services';
export const CONFIGURATOR_CATEGORY_PERIPHERALS = 'Peripherals';
export const CONFIGURATOR_CATEGORY_SOFTWARE = 'Software';

export const CONFIGURATOR_CATEGORY_CASE = 'Case';
export const CONFIGURATOR_CATEGORY_MOTHERBOARDS = 'Motherboards';
export const CONFIGURATOR_CATEGORY_POWER_SUPPLY = 'Power Supplies';
export const CONFIGURATOR_CATEGORY_GPU = 'GPU';
export const CONFIGURATOR_CATEGORY_CPU = 'CPU';
export const CONFIGURATOR_CATEGORY_CHIPSET = 'Chipset';

// access high quality assets based off of current build
export const CONFIGURATOR_BUILD_COLORS = 'Colors';

const CONFIGURATOR_RULE_ADD = 'add';
const CONFIGURATOR_RULE_ENABLE = 'enable';
// const CONFIGURATOR_RULE_DISABLE_ALL = 'disable-all';
const CONFIGURATOR_RULE_ENABLE_ALL = 'enable-all';
const CONFIGURATOR_RULE_MESSAGE = 'message';
const CONFIGURATOR_RULE_PREVENT = 'prevent';
const CONFIGURATOR_RULE_RESET = 'reset';
const CONFIGURATOR_RULE_SWITCH = 'switch';

export const CONFIGURATOR_RULETYPES_MAP = {
  [CONFIGURATOR_RULE_ADD]: 'info',
  [CONFIGURATOR_RULE_ENABLE]: 'success',
  // [CONFIGURATOR_RULE_DISABLE_ALL]: 'info',
  [CONFIGURATOR_RULE_ENABLE_ALL]: 'success',
  [CONFIGURATOR_RULE_MESSAGE]: 'info',
  [CONFIGURATOR_RULE_PREVENT]: 'error',
  [CONFIGURATOR_RULE_RESET]: 'info',
  [CONFIGURATOR_RULE_SWITCH]: 'info',
};

export const CONFIGURATOR_RULE_TITLE = {
  [CONFIGURATOR_RULE_ADD]: 'build_change_label',
  [CONFIGURATOR_RULE_ENABLE]: 'success_label',
  // [CONFIGURATOR_RULE_DISABLE_ALL]: 'build_info_message',
  [CONFIGURATOR_RULE_ENABLE_ALL]: 'success_label',
  [CONFIGURATOR_RULE_MESSAGE]: 'build_info_message',
  [CONFIGURATOR_RULE_PREVENT]: 'invalid_action_label',
  [CONFIGURATOR_RULE_RESET]: 'build_change_label',
  [CONFIGURATOR_RULE_SWITCH]: 'build_change_label',
};

export const CONFIGURATOR_STORAGE_FILTER = [
  { label: 'SSD', value: 'ssd' },
  { label: 'HDD', value: 'hdd' },
];

export const CONFIGURATOR_CASES_FILTER = [
  { label: 'H710', value: 'h710' },
  { label: 'H510', value: 'h510' },
  { label: 'H210', value: 'h210' },
  { label: 'H1', value: 'h1' },
];

export const CONFIGURATOR_COOLING_FILTER = [
  { label: 'Air', value: 'fans' },
  { label: 'Liquid', value: 'aio' },
];

export const CONFIGURATOR_CHIPSET_FILTER = [
  { label: 'Intel', value: 'intel' },
  { label: 'AMD', value: 'amd' },
];

export const CONFIGURATOR_FILTER_MAP = {
  [CONFIGURATOR_CATEGORY_STORAGE]: CONFIGURATOR_STORAGE_FILTER,
  [CONFIGURATOR_CATEGORY_COOLING]: CONFIGURATOR_COOLING_FILTER,
  [CONFIGURATOR_CATEGORY_CASE]: CONFIGURATOR_CASES_FILTER,
  [CONFIGURATOR_CATEGORY_MOTHERBOARDS]: CONFIGURATOR_CHIPSET_FILTER,
  [CONFIGURATOR_CATEGORY_GPU]: CONFIGURATOR_CHIPSET_FILTER,
  [CONFIGURATOR_CATEGORY_CPU]: CONFIGURATOR_CHIPSET_FILTER,
};

// TODO: const these?
export const SUMMARY_ROW_TRANSLATION_MAP = {
  case: 'custom_bld_case',
  cpu: 'custom_bld_cpu',
  gpu: 'custom_bld_gpu',
  motherboard: 'custom_bld_motherboard',
  ram: 'custom_bld_ram',
  ssd: 'custom_bld_ssd',
  cpuCooler: 'custom_bld_caseCooling',
  powerSupply: 'custom_bld_powerSupplies',
  software: 'custom_bld_software',
  services: 'custom_bld_services',
  monitors: 'custom_bld_monitors',
  peripherals: 'custom_bld_peripherals',
  hdd: 'custom_bld_hdd',
  extras: 'custom_bld_extras',
  specials: 'custom_bld_specials',
  rgbs: 'custom_bld_rgb_lighting',
  audio: 'custom_bld_audio',
  caseFans: 'custom_bld_fans',
  colors: 'custom_bld_colors',
};

export const CAT_NAMES = {
  chipset: {
    externalCatName: 'custom_bld_chipset',
    internalCatName: 'chipset',
    nextCategoryExternal: 'custom_bld_case',
    nextCategoryInternal: 'case',
    previousCategoryExternal: 'custom_bld_chipset',
    previousCategoryInternal: 'chipset',
  },
  case: {
    externalCatName: 'custom_bld_case',
    internalCatName: 'case',
    previousCategoryExternal: 'custom_bld_chipset',
    previousCategoryInternal: 'chipset',
    nextCategoryExternal: 'custom_bld_caseCooling',
    nextCategoryInternal: 'caseCooling',
  },
  cooling: {
    externalCatName: 'custom_bld_caseCooling',
    internalCatName: 'caseCooling',
    previousCategoryExternal: 'custom_bld_case',
    previousCategoryInternal: 'case',
    nextCategoryExternal: 'custom_bld_motherboards',
    nextCategoryInternal: 'motherboards',
  },
  motherboards: {
    externalCatName: 'custom_bld_motherboards',
    internalCatName: 'motherboards',
    previousCategoryExternal: 'custom_bld_caseCooling',
    previousCategoryInternal: 'caseCooling',
    nextCategoryExternal: 'custom_bld_gpu',
    nextCategoryInternal: 'gpu',
  },
  gpu: {
    externalCatName: 'custom_bld_gpu',
    internalCatName: 'gpu',
    previousCategoryExternal: 'custom_bld_motherboards',
    previousCategoryInternal: 'motherboards',
    nextCategoryExternal: 'custom_bld_cpu',
    nextCategoryInternal: 'cpu',
  },
  cpu: {
    externalCatName: 'custom_bld_cpu',
    internalCatName: 'cpu',
    previousCategoryExternal: 'custom_bld_gpu',
    previousCategoryInternal: 'gpu',
    nextCategoryExternal: 'custom_bld_powerSupplies',
    nextCategoryInternal: 'powerSupplies',
  },
  powerSupplies: {
    externalCatName: 'custom_bld_powerSupplies',
    internalCatName: 'powerSupplies',
    previousCategoryExternal: 'custom_bld_cpu',
    previousCategoryInternal: 'cpu',
    nextCategoryExternal: 'custom_bld_ram',
    nextCategoryInternal: 'ram',
  },
  ram: {
    externalCatName: 'custom_bld_ram',
    internalCatName: 'ram',
    previousCategoryExternal: 'custom_bld_powerSupplies',
    previousCategoryInternal: 'powerSupplies',
    nextCategoryExternal: 'custom_bld_storage',
    nextCategoryInternal: 'storage',
  },
  storage: {
    externalCatName: 'custom_bld_storage',
    internalCatName: 'storage',
    previousCategoryExternal: 'custom_bld_ram',
    previousCategoryInternal: 'ram',
    nextCategoryExternal: 'custom_bld_monitors',
    nextCategoryInternal: 'monitors',
  },
  monitors: {
    externalCatName: 'custom_bld_monitors',
    internalCatName: 'monitors',
    previousCategoryExternal: 'custom_bld_storage',
    previousCategoryInternal: 'storage',
    nextCategoryExternal: 'custom_bld_peripherals',
    nextCategoryInternal: 'peripherals',
  },
  peripherals: {
    externalCatName: 'custom_bld_peripherals',
    internalCatName: 'peripherals',
    previousCategoryExternal: 'custom_bld_monitors',
    previousCategoryInternal: 'monitors',
    nextCategoryExternal: 'custom_bld_lighting',
    nextCategoryInternal: 'rgbs',
  },
  lighting: {
    externalCatName: 'custom_bld_rgb_lighting',
    internalCatName: 'rgbs',
    previousCategoryExternal: 'custom_bld_peripherals',
    previousCategoryInternal: 'peripherals',
    nextCategoryExternal: 'custom_bld_extras',
    nextCategoryInternal: 'extras',
  },
  extras: {
    externalCatName: 'custom_bld_extras',
    internalCatName: 'extras',
    previousCategoryExternal: 'custom_bld_rgb_lighting',
    previousCategoryInternal: 'rgbs',
    nextCategoryExternal: 'custom_bld_software',
    nextCategoryInternal: 'software',
  },
  software: {
    externalCatName: 'custom_bld_software',
    internalCatName: 'software',
    previousCategoryExternal: 'custom_bld_extras',
    previousCategoryInternal: 'extras',
    nextCategoryExternal: 'custom_bld_services',
    nextCategoryInternal: 'services',
  },
  services: {
    externalCatName: 'custom_bld_services',
    internalCatName: 'services',
    previousCategoryExternal: 'custom_bld_software',
    previousCategoryInternal: 'software',
    nextCategoryExternal: 'summary',
    nextCategoryInternal: 'summary',
  },
};

export const CATEGORY_FILTER = {
  core: [
    'chipset',
    'case',
    'motherboards',
    'caseCooling',
    'gpu',
    'cpu',
    'powerSupplies',
    'ram',
    'storage',
  ],
  addOns: ['rgbs', 'monitors', 'peripherals', 'extras', 'software', 'services'],
};

// KB LANGS
export const EN_QWERTY = 'en-qwerty';
export const UK_QWERTY = 'uk-qwerty';
export const DE_QWERTZ = 'de-qwertz';
export const FR_AZERTY = 'fr-azerty';

// KB LEGENDS
export const LEGENDS_ISO = {
  'fr-azerty': {
    black: '06-Legends-FR-Black.png',
    grey: '06-Legends-FR-Cool-Gray.png',
    inverted: '06-Legends-FR-Inverted.png',
    mixed: '06-Legends-FR-Mixed.png',
  },
  'de-qwertz': {
    black: '06-Legends-GE-Black.png',
    grey: '06-Legends-GE-Cool-Gray.png',
    inverted: '06-Legends-GE-Inverted.png',
    mixed: '06-Legends-GE-Mixed.png',
  },
  'uk-qwerty': {
    black: '06-Legends-UK-Black.png',
    grey: '06-Legends-UK-Cool-Gray.png',
    inverted: '06-Legends-UK-Inverted.png',
    mixed: '06-Legends-UK-Mixed.png',
  },
  'en-qwerty': {
    black: '06-Legends-UK-Black.png',
    grey: '06-Legends-UK-Cool-Gray.png',
    inverted: '06-Legends-UK-Inverted.png',
    mixed: '06-Legends-UK-Mixed.png',
  },
};

export const LEGENDS_ANSI = {
  'en-qwerty': {
    black: '06-Legends-EN-Black.png',
    grey: '06-Legends-EN-Cool-Gray.png',
    inverted: '06-Legends-EN-Inverted.png',
    mixed: '06-Legends-EN-Mixed.png',
  },
};
