/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export const CUSTOMER_TOKEN_COOKIE = 'customerToken';
export const NEXTAUTH_COOKIE_SESSION_TOKEN = 'next-auth.session-token';
export const NEXTAUTH_COOKIE_CALLBACK_URL = 'next-auth.callback-url';
export const NEXTAUTH_COOKIE_CSRF_TOKEN = 'next-auth.csrf-token';

export const USERNAME_PATTERN_REGEX =
  /(?=^.{6,30}$)^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-zA-Z0-9.]+$/g;

/* OIDC cookies */
export const OIDC_INTERACTION_COOKIE_NAME = '_interaction';
export const OIDC_INTERACTION_SIG_COOKIE_NAME = '_interaction.sig';
export const OIDC_INTERACTION_RESUME_COOKIE_NAME = '_interaction_resume';
export const OIDC_INTERACTION_RESUME_SIG_COOKIE_NAME =
  '_interaction_resume.sig';
export const OIDC_SESSION_COOKIE_NAME = '_session';
export const OIDC_SESSION_SIG_COOKIE_NAME = '_session.sig';
export const OIDC_SESSION_LEGACY_COOKIE_NAME = '_session.legacy';
export const OIDC_SESSION_LEGACY_SIG_COOKIE_NAME = '_session.legacy.sig';
export const OIDC_FEDERATED_GOOGLE_STATE_COOKIE_NAME = 'google.state';
