export const DEFAULT_LANGUAGE_CODE = 'en';
export const GERMAN_LANGUAGE_CODE = 'de';
export const DUTCH_LANGUAGE_CODE = 'nl';
export const FRENCH_LANGUAGE_CODE = 'fr';
export const ITALIAN_LANGUAGE_CODE = 'it';
export const SPANISH_LANGUAGE_CODE = 'es';

export const LANGUAGES = [
  {
    label: 'English',
    value: DEFAULT_LANGUAGE_CODE,
  },
  {
    label: 'Deutsch',
    value: GERMAN_LANGUAGE_CODE,
  },
  {
    label: 'Dutch',
    value: DUTCH_LANGUAGE_CODE,
  },
  {
    label: 'Français',
    value: FRENCH_LANGUAGE_CODE,
  },
  {
    label: 'Italiano',
    value: ITALIAN_LANGUAGE_CODE,
  },

  {
    label: 'Español',
    value: SPANISH_LANGUAGE_CODE,
  },
];
