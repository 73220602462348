/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
import Script from 'next/script';

// TODO - make these types better
declare global {
  interface Window {
    Extend?: any;
    ExtendShopify?: any;
  }
}

const ExtendConfig = () => {
  return (
    <>
      <Script
        id="extend-sdk"
        src="https://sdk.helloextend.com/extend-sdk-client/v1/extend-sdk-client.min.js"
        strategy="lazyOnload"
        onLoad={() => {
          if (typeof window !== 'undefined') {
            window.Extend.config({
              storeId: process.env.NEXT_PUBLIC_EXTEND_STORE_ID,
              environment: process.env.NEXT_PUBLIC_EXTEND_ENV,
            });
          }
        }}
      />
      <Script
        id="extend-shopify-sdk"
        src="https://sdk.helloextend.com/extend-sdk-client-shopify-addon/v1/extend-sdk-client-shopify-addon.min.js"
        strategy="lazyOnload"
        onLoad={() => {
          window.setTimeout(() => {
            window.dispatchEvent(new CustomEvent('ExtendLoaded'));
          }, 1000);
        }}
      />
    </>
  );
};
export default ExtendConfig;
